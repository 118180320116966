import { Component, inject, Injector, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { ValidationHelperService } from '@common-modules/shared/helpers/validation-helper.service';
import { finalize, map, Observable, of, switchMap } from 'rxjs';
import { FilterGroupFieldSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-group-field-settings';
import { FilterGroupSettings } from 'src/app/common-modules/dependencies/wlm-filters/filter-group-settings';
import { ElementTypeFilter } from 'src/app/common-modules/dependencies/wlm-filters/i-filters/element-type-filter';
import { GenericCrudSettings } from 'src/app/common-modules/generic-crud/generic-crud-settings';
import { GenericCrudComponent } from 'src/app/common-modules/generic-crud/generic-crud/generic-crud.component';
import { AppModules } from 'src/app/common-modules/shared/app-modules.enum';
import { DialogService } from 'src/app/common-modules/shared/dialogs/dialogs.service';
import { DataBindingFilters } from 'src/app/common-modules/shared/filters/component-filters/data-binding-filters';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { GisLayerKpiConversionDto } from '../gis-layer-kpi-conversion.dto';
import { GisLayerKpiCrudService } from '../gis-layer-kpi-crud-service';
import { GisLayerKpiFormComponent } from '../gis-layer-kpi-form/gis-layer-kpi-form.component';
import { GisLayerKpiConfigurationDto } from '../gis-layer-kpi.dto';

const COMPONENT_SELECTOR = 'wlm-gis-layer-kpi-crud';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './gis-layer-kpi-crud.component.html',
  styleUrl: './gis-layer-kpi-crud.component.scss',
})
export class GisLayerKpiCrudComponent {
  @ViewChild(GenericCrudComponent) genericCrudComponent: GenericCrudComponent;
  settings: GenericCrudSettings;
  isZone: boolean = false;
  selectedItem: GisLayerKpiConfigurationDto;
  selectedItems: GisLayerKpiConversionDto[];
  filterSettings: FilterGroupSettings;
  canLoadFilter = false;
  filters: DataBindingFilters;
  gisLayerId: number;
  filtersSetted = false;
  setLoading: (isLoading: boolean) => void;
  private _gisLayerIdFieldName = 'gisLayerId';

  private readonly _injector = inject(Injector);
  private readonly _dialogService = inject(DialogService);
  private readonly _gisLayerKpiCrudService = inject(GisLayerKpiCrudService);
  private readonly _spinnerService = inject(SpinnerService);
  private readonly _validationHelperService = inject(ValidationHelperService);
  readonly T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;

  constructor() {
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  ngOnInit(): void {
    this.buildSettings();
    this.setFilterSettings();
  }

  buildSettings(): void {
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      height: '500px',
    };
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'GisLayerKpiCrudService',
      grid: {
        gridSettingsName: 'GisLayerKpiConfiguration',
      },
      create: {
        formComponent: GisLayerKpiFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(true),
        dialogConfig: dialogConfig,
      },
      update: {
        formComponent: GisLayerKpiFormComponent,
        dialogConfig: dialogConfig,
      },
    });
  }

  onSelectedItem(item: GisLayerKpiConfigurationDto): void {
    this.selectedItem = item;
  }

  onSelectedItemsChange(items: GisLayerKpiConversionDto[]): void {
    this.selectedItems = items;
  }

  onDeleteConfirm(): void {
    const confirmMessageKey = this.getConfirmDeleteMessageKey();
    this._dialogService
      .showTranslatedDialogMessage({
        translateKey: confirmMessageKey,
        icon: 'warning',
      })
      .pipe(
        switchMap((result) => {
          if (result.result) {
            this.setLoading(true);
            const configuration: GisLayerKpiConfigurationDto = {
              gisLayerId: this.selectedItems[0].gisLayerId,
              kpis: this.selectedItems.map((item) => ({
                kpiType: item.kpiType,
                kpiProperty: item.kpiProperty,
                dimensionTypeId: item.dimensionTypeId,
                timeAggregationId: item.timeAggregationId,
              })),
            };
            return this._gisLayerKpiCrudService.deleteConfiguration(configuration);
          } else {
            return of(null);
          }
        }),
        finalize(() => {
          this.setLoading(false);
        })
      )
      .subscribe({
        next: (result) => {
          if (result) {
            this.genericCrudComponent.refreshItems();
            this._dialogService.showEntityActionSnackBar('delete', 'configuration');
          }
        },
        error: (error) => {
          this._dialogService.showErrorMessage(error);
        },
      });
  }
  private getConfirmDeleteMessageKey(): string {
    return 'common.messages.delete-confirm-generic';
  }

  setDataBindingFilters(filtersParameters: DataBindingFilters): void {
    this.canLoadFilter = filtersParameters.exportableFilter !== undefined;
    this.filters = filtersParameters;
  }

  private setFilterSettings() {
    const fields: { [field: string]: FilterGroupFieldSettings } = {
      networkElementType: new FilterGroupFieldSettings({
        fieldName: this._gisLayerIdFieldName,
      }),
    };

    this.filterSettings = new FilterGroupSettings({
      fields,
      persistencyArea: globalUtilsHelper.generateGuid(),
    });
  }

  private getNetworkElementTypeId(): number {
    if (!this.filters) {
      return null;
    }
    const filter = this.filters.filters.get(this._gisLayerIdFieldName) as ElementTypeFilter;
    const selection = filter?.selectedElements;
    const id = selection?.length ? selection[0] : selection;
    this.gisLayerId = id;
    return id;
  }

  applyFilters(): void {
    if (this.canLoadFilter) {
      const gisLayerId = this.getNetworkElementTypeId();
      this.genericCrudComponent.applyExternalFilters({
        gisLayerId,
      });
      this.filtersSetted = true;
    }
  }

  onClearAll(): void {
    this.filters = null;
    this.genericCrudComponent.applyExternalFilters(null);
    this.filtersSetted = false;
  }

  private buildBeforeSaveHook =
    (isCreate: boolean) =>
    (model: GisLayerKpiConfigurationDto): Observable<GisLayerKpiConfigurationDto | null> => {
      return this._gisLayerKpiCrudService.validate(model).pipe(
        map((isValid) => {
          if (!isValid) {
            this._validationHelperService.showNotUniqueError(
              `${this.T_SCOPE}.properties.gis-layer-kpi-property`
            );
            return null;
          }
          return model;
        })
      );
    };
}
