import { Injector, Type } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { IntegrableForm } from '../shared/forms/integrable-form';

export type TGenericCrudBeforeSaveHook = (model) => Observable<any | null>;

export type TGenericCrudDisableHook = (model) => Observable<boolean>;

export type TGenericCrudDeleteDisableHook = (model) => Observable<any | null>;

export class GenericCrudPopupSettings {
  titleKey?: string;
  buttonKey?: string;
  formComponent: Type<IntegrableForm>;
  // Allows to call api validations before saving and cancelling the flow if needed.
  beforeSaveHook?: TGenericCrudBeforeSaveHook;
  // Allows to disable buttons to open the popup.
  disableHook?: TGenericCrudDisableHook;
  // Only for update. These entities will always appear as readonly.
  readonlyEntityIds?: any[];
  dialogConfig?: MatDialogConfig;
}

export class GenericCrudSettings {
  injector: Injector;
  service: string;

  grid: {
    gridSettingsName: string;
  };

  create?: GenericCrudPopupSettings;

  update?: GenericCrudPopupSettings;

  delete?: {
    confirmMessageKey?: string;
    disableHook?: TGenericCrudDisableHook;
    beforeDeleteHook?: TGenericCrudDeleteDisableHook;
  };

  constructor(data: GenericCrudSettings) {
    Object.assign(this, data);
  }
}
