import {
  Component,
  DestroyRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, NgForm } from '@angular/forms';
import { NetworkElementAttributeTypeDto } from '@common-modules/dependencies/ne/network-element-attribute-type.dto';
import { INetworkElementTypeDto } from '@common-modules/dependencies/ne/network-element-type.dto';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { LogService } from '@common-modules/shared/wlm-log/log.service';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import {
  Observable,
  Subject,
  Subscription,
  finalize,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { GlobalSettingsService } from '../../../shared/global-settings/global-settings.service';
import { WlmGlobalSettings } from '../../../shared/global-settings/wlm-global-settings.enum';
import { NetNeatLinkCrudService } from '../../net-neat-link/net-neat-link-crud-service';
import { IntegrationGisLayersService } from '../integration-gis-layers.service';

type TAttribute = {
  order: number;
  networkElementAttributeTypeId: number;
  networkElementAttributeTypeName: string;
  attributeDataTypeId: number;
  mandatory: boolean;
  default: boolean;
  isHierarchyElement: boolean;
  isNetworkElement: boolean;
  isGisTooltip: boolean;
};

type TAttributeSetting = {
  id: number;
  mandatory: boolean;
  default: boolean;
  delete: boolean;
  isHierarchyElement: boolean;
  isNetworkElement: boolean;
};

const EXCLUDED_ATTRIBUTE_TYPE_ID = 10027;

const COMPONENT_SELECTOR = 'wlm-integration-gis-layers-mapping';
@Component({
  selector: 'wlm-integration-gis-layers-mapping',
  templateUrl: './integration-gis-layers-mapping.component.html',
  styleUrls: ['./integration-gis-layers-mapping.component.scss'],
})
export class IntegrationGisLayersMappingComponent implements OnInit {
  @ViewChild('form') set queryForm(form: NgForm) {
    this._form = form;
    this.buildForm();
  }

  @Output() canSaveForm = new EventEmitter<boolean>();

  private readonly _service = inject(IntegrationGisLayersService);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _globalsService = inject(GlobalsService);
  private readonly _globalSettingsService = inject(GlobalSettingsService);
  private readonly _spinnerService = inject(SpinnerService);
  private readonly _dialogsService = inject(DialogService);
  private readonly _log = inject(LogService);
  private readonly _linksService = inject(NetNeatLinkCrudService);
  private readonly _integrationGisLayersService = inject(IntegrationGisLayersService);

  readonly descriptionAttribute: string = '--description-attribute';
  readonly T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;
  private _attributes: TAttribute[] = [];
  public get attributes(): TAttribute[] {
    return this._attributes ?? [];
  }
  public set attributes(value: TAttribute[]) {
    this._attributes = value;
  }
  elements: any[] = [];
  fields: any[] = [];
  layer: INetworkElementTypeDto;
  optionalAdded: number[];
  gisKey: string;
  layerNumber: number;
  attributeValues: { [key: string]: string };
  networkElementAttributeTypes: { [key: number]: NetworkElementAttributeTypeDto } = {};
  isValid = false;
  isFormSaved = false;
  selectedNEType: INetworkElementTypeDto;
  currentLinkedAttributes: TAttribute[];
  private _formValueChanges$ = new Subject<any>();
  formValueChanges$ = this._formValueChanges$.asObservable();
  private setLoading: (loading: boolean) => void;
  private _form: NgForm;
  private _valueChangesSubs: Subscription;
  private _loadAttributesSubs: Subscription;

  constructor() {
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  private buildForm(): void {
    if (this._form) {
      this.checkIsValid();
      this.canSaveForm.emit(this._form.pristine && this.isValid);

      this._valueChangesSubs?.unsubscribe();
      this._valueChangesSubs = this._form.valueChanges.subscribe((value) => {
        this.checkIsValid();
        this.canSaveForm.emit(this._form ? !this._form.dirty : false);
        this._formValueChanges$.next(value);
      });

      const gisKeyControl = this.gisKeyControl();
      if (gisKeyControl) {
        this.gisKey = gisKeyControl.value;
        this.checkIsValid();
        gisKeyControl.valueChanges.subscribe((gisKey) => {
          this.gisKey = gisKey;
          this.checkIsValid();
        });
      }
    }
  }

  private checkIsValid = () => {
    this.isValid = this._form?.valid && this.validate();
  };

  init() {
    this.attributes = [];
    this.currentLinkedAttributes = [];
    this.elements = [];
    this.fields = [];
    this.layer = undefined;
    this.optionalAdded = [];
    this.gisKey = null;
    this.layerNumber = 0;
    this.attributeValues = {};
  }

  hasRequiredError = (option: TAttribute) => {
    const value = this.attributeValues[option.networkElementAttributeTypeId.toString()];

    const result = option.mandatory && value == null;
    return result;
  };

  getAttributeControl(option: TAttribute): AbstractControl | null {
    if (!this._form) {
      return null;
    }

    const control = this._form.controls[this.attributeFieldName(option)];
    return control;
  }

  get attributesMandatory(): TAttribute[] {
    return this.attributes.filter((x) => x.mandatory).sort((x) => x.order);
  }

  get attributesDefault(): TAttribute[] {
    return this.attributes
      .filter((x) => !x.mandatory && (x.default || x.isGisTooltip))
      .sort((x) => x.order);
  }

  get attributesOptional(): TAttribute[] {
    return this.currentLinkedAttributes
      .filter(
        (x) =>
          !this.attributesMandatory
            .map((a) => a.networkElementAttributeTypeId)
            .includes(x.networkElementAttributeTypeId) &&
          !this.attributesDefault
            .map((b) => b.networkElementAttributeTypeId)
            .includes(x.networkElementAttributeTypeId)
      )
      .sort((x) => x.order);
  }

  get attributesOptionalAvailable(): TAttribute[] {
    return this.attributesOptional
      .filter((x) => !this.optionalAdded.includes(x.networkElementAttributeTypeId))
      .sort((x) => x.order);
  }

  get attributesOptionalAdded(): TAttribute[] {
    return this.attributesOptional
      .filter((x) => this.optionalAdded.includes(x.networkElementAttributeTypeId))
      .sort((x) => x.order);
  }

  get layerNumbers(): number[] {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  }

  ngOnInit(): void {
    this.init();

    this._service.initialLoad();

    this._globalsService.getNetworkElementAttributesType().subscribe(
      (x) =>
        (this.networkElementAttributeTypes = x.reduce((acc, element) => {
          acc[element.networkElementAttributeTypeId] = element;
          return acc;
        }, {} as { [key: number]: NetworkElementAttributeTypeDto }))
    );

    this._service
      .getFields()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((fields) => (this.fields = fields));

    this._service
      .getElements()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((elements) => (this.elements = elements));

    this.loadLayer$().subscribe((layer) => {
      if (!layer) {
        return;
      }

      this.layer = layer;

      this._loadAttributesSubs?.unsubscribe();
      this.setLoading(true);
      this._loadAttributesSubs = this.loadAttributes$().subscribe(([attributes, settings]) => {
        this.initAttributes(attributes, settings);

        if (attributes !== undefined) {
          this.setLoading(false);
        }
      });
    });
  }

  private loadLayer$(): Observable<INetworkElementTypeDto> {
    return this._service
      .getLayer()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .pipe(
        switchMap((layerId) => {
          if (layerId == 0) {
            return of(null);
          } else {
            return this._globalsService.getNetworkElementTypes().pipe(
              map((layers) => layers.find((l) => l.networkElementTypeId == layerId)),
              tap((ne) => (this.selectedNEType = ne))
            );
          }
        })
      );
  }

  private loadAttributes$(): Observable<any[]> {
    return this._service.getLayer().pipe(
      takeUntilDestroyed(this._destroyRef),
      switchMap((layerId) => {
        if (layerId == 0) {
          return of([]);
        } else {
          return forkJoin([
            this.getAttributes(layerId),
            this._globalSettingsService.load(WlmGlobalSettings.GisSettings),
          ]);
        }
      })
    );
  }

  delete() {
    if (!this.layer?.networkElementTypeId) {
      return;
    }

    const updates: { [key: string]: any } = {};

    updates[`$.GeoJsonMapping.Ids.${this.layer.gisLayerId}`] = [];
    updates[`$.GeoJsonMapping.Attributes.${this.layer.gisLayerId}`] = {};
    updates[`$.GeoJsonMapping.Descriptions.${this.layer.gisLayerId}`] = {};

    this.setLoading(true);
    this._globalSettingsService
      .partial(WlmGlobalSettings.GisSettings, updates)
      .pipe(finalize(() => this.setLoading(false)))
      .subscribe({
        next: () => {
          this.showSuccess();
          this.canSaveForm.emit(true);
        },
        error: this._dialogsService.showErrorMessage,
      });
  }

  onDeleteConfirm(event): void {
    this._dialogsService
      .showTranslatedDialogMessage({
        translateKey: 'common.messages.reset-confirm-generic',
        icon: 'warning',
      })
      .subscribe((result) => {
        if (result.result) {
          this.delete();
          this.resetForm();
        }
      });
  }

  clear(): void {
    this.init();
  }

  private resetForm(): void {
    this.attributeValues = {};
    this.gisKey = null;
  }

  save() {
    if (!this.layer?.networkElementTypeId) {
      return;
    }

    if (!this.gisKey) {
      return;
    }

    const attributes = this.getMappingConfigAttributes();

    if (!attributes) {
      return;
    }

    if (!this.validate()) {
      return;
    }

    const updates: { [key: string]: any } = {};

    updates[`$.GeoJsonMapping.Ids.${this.layer.gisLayerId}`] = [this.gisKey];

    for (const key in attributes) {
      const value = attributes[key];
      updates[`$.GeoJsonMapping.Attributes.${this.layer.gisLayerId}.${key}`] = value;
    }

    this.setLoading(true);
    this._globalSettingsService
      .partial(WlmGlobalSettings.GisSettings, updates)
      .pipe(finalize(() => this.setLoading(false)))
      .subscribe({
        next: () => {
          this.isFormSaved = true;
          this.canSaveForm.emit(true);
          this._integrationGisLayersService.setMappingsFormValidAndSendRequest(true);
          this.showSuccess();
        },
        error: this._dialogsService.showErrorMessage,
      });
  }

  getMappingConfigAttributes(): { [key: string]: number[] } {
    const values: { [key: string]: number[] } = {};

    for (const key in this.attributeValues) {
      if (this.attributeValues[key] !== undefined && !key.endsWith(this.descriptionAttribute)) {
        const value = this.attributeValues[key];

        if (value === undefined) {
          continue;
        }

        let existingValue = values[value];

        if (existingValue === undefined) {
          existingValue = [Number(key)];
        } else {
          existingValue.push(Number(key));
        }

        values[value] = existingValue;
      }
    }

    return values;
  }

  attributeFieldName = (option): string => `att-${option.networkElementAttributeTypeId}`;

  gisKeyControl = () => this._form?.controls['gisKey'];

  initAttributes(attributes: TAttribute[], gisSettings: any) {
    this.attributes = attributes;

    const currentLayer = this.getLayerCurrentFromSettings(gisSettings);
    const allAcc = [{}];
    if (currentLayer?.gisKey?.length) {
      this.gisKey = currentLayer.gisKey[0];
      this.checkIsValid();
      this.checkForAdditionalMappings(currentLayer?.attributes);
    }

    this.attributeValues = this.attributes.reduce((acc, attr) => {
      acc[attr.networkElementAttributeTypeId.toString()] = this.getAttributeCurrentFromSettings(
        attr.networkElementAttributeTypeId,
        currentLayer.attributes
      );

      allAcc[0] = { ...acc };

      return acc;
    }, {} as { [key: string]: string });

    this.compareAttributes(allAcc, this.gisKey);
  }

  compareAttributes(attributes: { [key: string]: string }[], gisKey: string) {
    if (!attributes) {
      return;
    }

    this.formValueChanges$.subscribe((value) => {
      const isEquals = attributes.some((attr) => {
        return (
          Object.keys(attr).every((key) => value[`att-${key}`] === attr[key]) &&
          value.gisKey === gisKey
        );
      });

      if (isEquals) {
        this.isFormSaved = true;
        if (this._form?.valid && this.validate()) {
          this._integrationGisLayersService.setMappingsFormValidAndSendRequest(true);
        }
      } else {
        this.isFormSaved = false;
        this._integrationGisLayersService.setMappingsFormValidAndSendRequest(false);
      }
    });
  }

  checkForAdditionalMappings(attributes: any) {
    const attribExtracted = !attributes
      ? []
      : (Object.values(attributes).reduce((a: number[], c: number) => a.concat(c)) as number[]);
    const mappedAttributes = [...new Set(attribExtracted)];

    this.optionalAdded = mappedAttributes.filter(
      (x) => !this.attributes.map((y) => y.networkElementAttributeTypeId).includes(x)
    );
    this.attributes = this.attributes.concat(
      this.currentLinkedAttributes.filter((x) =>
        this.optionalAdded.includes(x.networkElementAttributeTypeId)
      )
    );
  }

  getAttributeCurrentFromSettings(
    networkElementAttributeTypeId: number,
    attributes: { [key: string]: number[] }
  ) {
    if (!attributes) {
      return undefined;
    }

    const availablePropertyNames = this._service.getPropertyNames();

    if (!availablePropertyNames.length) {
      this._log.error({ msg: 'At least one available property must exist for mapping' });
    }

    for (const key in attributes) {
      for (const id of attributes[key]) {
        if (id == networkElementAttributeTypeId && availablePropertyNames.includes(key)) {
          return key;
        }
      }
    }
    return undefined;
  }

  private getLayerCurrentFromSettings(gisSettings: any) {
    const mapping = gisSettings?.GeoJsonMapping;
    const gisLayerId = this.layer.gisLayerId;
    const attributes = mapping?.Attributes ? mapping.Attributes[gisLayerId] : [];
    const gisKey = mapping?.Ids ? mapping.Ids[gisLayerId] : null;

    return {
      attributes,
      gisKey,
    };
  }

  toggleOptional(attribute: TAttribute) {
    const index = this.optionalAdded.findIndex((x) => x == attribute.networkElementAttributeTypeId);

    if (index < 0) {
      this.addOptional(attribute);
    } else {
      this.removeOptional(attribute);
    }
  }

  addOptional(attribute: TAttribute) {
    this.optionalAdded.push(attribute.networkElementAttributeTypeId);
  }

  removeOptional(attribute: TAttribute) {
    const index = this.optionalAdded.findIndex((x) => x == attribute.networkElementAttributeTypeId);

    if (index >= 0) {
      const deletedElements = this.optionalAdded.splice(index, 1);

      if (deletedElements.length !== 1) {
        return;
      }

      var deleted = deletedElements[0];
      this.attributeValues[deleted.toString()] = this.attributeValues[
        `${deleted}${this.descriptionAttribute}`
      ] = undefined;
    }
  }

  isOptionalAdded(attribute: TAttribute) {
    const index = this.optionalAdded.findIndex((x) => x == attribute.networkElementAttributeTypeId);
    return index >= 0;
  }

  private validate(): boolean {
    return !!this.gisKey;
  }

  private getAttributes(layerId: number): Observable<TAttribute[]> {
    return forkJoin({
      config: this._globalSettingsService.load(WlmGlobalSettings.GisSettingsAttributes),
      linkAttributes: this._linksService.getAll({ networkElementTypeId: layerId }),
    }).pipe(
      map(({ config, linkAttributes }) => {
        this.currentLinkedAttributes = (linkAttributes as unknown as TAttribute[]) ?? [];
        const attributes = [] as TAttribute[];
        const configLayerAttributes = (config[layerId.toString()] as TAttributeSetting[]) ?? [];

        let layerAttributes = this.getLayerAttributes(configLayerAttributes);
        attributes.push(...layerAttributes);

        layerAttributes = this.getLayerAttributes(
          config['0'] as TAttributeSetting[],
          attributes.length
        ).filter(
          (x) =>
            !configLayerAttributes.map((y) => y.id).includes(x.networkElementAttributeTypeId) &&
            x?.isNetworkElement === this.selectedNEType.isNetworkElement
        );
        attributes.push(...layerAttributes);

        const filteredLinkAttributes = linkAttributes.filter(
          (attr) => attr.isGisTooltip
        ) as unknown as TAttribute[];

        const uniqueLinkAttributes = filteredLinkAttributes.filter(
          (attr) =>
            !attributes.some(
              (duplicatedAttr) =>
                duplicatedAttr.networkElementAttributeTypeId === attr.networkElementAttributeTypeId
            )
        );

        return [...attributes, ...uniqueLinkAttributes];
      })
    );
  }

  private getLayerAttributes(
    layerAttributes: TAttributeSetting[],
    order: number = 0
  ): TAttribute[] {
    const attributes = [] as TAttribute[];

    if (!layerAttributes?.length) {
      return attributes;
    }

    for (const layerAttribute of layerAttributes) {
      const attribute = this.networkElementAttributeTypes[layerAttribute.id];

      if (!attribute || layerAttribute.delete) {
        continue;
      }

      order++;

      attributes.push({
        order: order,
        networkElementAttributeTypeId: layerAttribute.id,
        networkElementAttributeTypeName: attribute.networkElementAttributeTypeName,
        attributeDataTypeId: attribute.attributeDataTypeId,
        mandatory: layerAttribute.mandatory ?? false,
        default: layerAttribute.default ?? false,
        isHierarchyElement: layerAttribute.isHierarchyElement,
        isNetworkElement: layerAttribute.isNetworkElement,
        isGisTooltip: attribute.isGisTooltip,
      });
    }

    const filterAttributes = attributes.filter(
      (attr) => attr.networkElementAttributeTypeId !== EXCLUDED_ATTRIBUTE_TYPE_ID
    );

    return filterAttributes;
  }

  private showSuccess(): void {
    this._dialogsService.showTranslatedMessageInSnackBar(
      new WlmDialogSettings({
        translateKey: 'common.messages.success',
        icon: 'success',
      })
    );
  }
}
