<div class="upper-spacer"></div>
<div class="filters-background">
  <wlm-ne-type-filter
    *ngIf="filterSettings"
    [settings]="filterSettings"
    [isGisEnabled]="true"
    (filtersChanged)="setDataBindingFilters($event)"
    (apply)="applyFilters()"
    (clearAll)="onClearAll()"
  ></wlm-ne-type-filter>
</div>
<wlm-generic-crud
  [hidden]="!filtersSetted"
  class="generic-crud"
  [settings]="settings"
  (selectedItemChange)="onSelectedItem($event)"
  (selectedItemsChange)="onSelectedItemsChange($event)"
>
  <ng-template
    genericCrudAdditionalButtons
    let-isContainerCollapsed="isContainerCollapsed"
    let-type="ultimate"
  >
    <button
      wlmCollapsibleButton
      [tooltip]="T_SCOPE + '.link-delete-custom'"
      [showLabel]="isContainerCollapsed"
      [disabled]="!selectedItems?.length"
      (click)="onDeleteConfirm()"
    >
      <wlm-icon icon="delete"></wlm-icon>
    </button> </ng-template
></wlm-generic-crud>
