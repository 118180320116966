import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { HttpCacheService } from '@common-modules/cache/http-cache/http-cache.service';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DateHelperService } from '@common-modules/shared/helpers/date-helper.service';
import { DateRange } from '@common-modules/shared/model/date/date-range';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { ReplaySubject } from 'rxjs';
import { ParkingOperationDto } from '../../../shared/model/alarms/parking-operation.dto';

import { AlarmsConfigurationService } from '../../alarms-configuration-page/alarms-configuration.service';

const COMPONENT_SELECTOR = 'wlm-alarms-parking-popup';
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './alarms-parking-popup.component.html',
  styleUrls: ['./alarms-parking-popup.component.scss'],
})
export class AlarmsParkingPopupComponent implements OnInit {
  public T_SCOPE = `${AppModules.Configuration}.${COMPONENT_SELECTOR}`;
  isSaving = false;
  form: UntypedFormGroup;
  daysOffset = 10;
  minDate = new Date();
  maxDate: Date;
  today = new Date();
  disabledUnPark = false;
  dateRangeValue: DateRange;
  selectedDates: DateRange;
  parkOperations: string[] = ['park', 'unpark'];
  disableStartDate = false;
  disableEndDate = false;
  selectedProfiles: string[];
  resetEndDate$ = new ReplaySubject<void>();
  resetStartDate$ = new ReplaySubject<void>();
  removeSelectionPersisted$ = new ReplaySubject<void>();

  public get formTitle(): string {
    return `${this.T_SCOPE}.title`;
  }

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _dialogRef: MatDialogRef<AlarmsParkingPopupComponent>,
    @Inject(MAT_DIALOG_DATA) { selectedProfiles }: any,
    private readonly _authenticationService: AuthenticationService,
    private readonly _alarmsConfigurationService: AlarmsConfigurationService,
    private readonly _cacheService: HttpCacheService,
    private readonly _dialogService: DialogService,
    private readonly _dateHelperService: DateHelperService
  ) {
    this.selectedProfiles = selectedProfiles;
    this.createForm();
  }

  ngOnInit(): void {}

  private createForm() {
    this.form = this._formBuilder.group({
      parkComment: [''],
      parkIndefinitely: [''],
      parkOperation: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDateDefinition: [true, [Validators.required]],
    });
    this.enableDisableFormFields('unpark');
    this.minDate = this.today;
  }

  onParkOperationChange(selectedOperation: MatRadioChange) {
    this.form.get('parkOperation').setValue(selectedOperation.value);
    this.enableDisableFormFields(selectedOperation.value);
    this.clearDatePickers(selectedOperation.value);
    this.validateDatesCompletion();
    this.clearCheckIndefinitely(selectedOperation.value);
  }

  clearCheckIndefinitely(value: string) {
    if (value === 'unpark') {
      this.form.get('parkIndefinitely').setValue(false);
    }
  }

  clearDatePickers(value: string) {
    if (value === 'unpark') {
      this.resetEndDate$.next();
      this.resetStartDate$.next();
      this.selectedDates = new DateRange(null);
    }
  }

  onIndefinitelyChange(selectIndefinitely: MatCheckboxChange) {
    this.resetEndDate$.next();
    this.disableEndDate = selectIndefinitely.checked;
    this.validateDatesCompletion();
  }

  validateDatesCompletion() {
    if (this.form.get('parkOperation').value === 'park') {
      // validates check indefinitely and endDate
      const validation =
        this.form.get('parkIndefinitely').value || this.selectedDates?.end ? true : null;

      // startdate should be recalculated to be validated
      const startValue =
        this.selectedDates?.start &&
        this.selectedDates.start.toDateString() !== new Date(null).toDateString()
          ? this.selectedDates.start
          : null;
      this.updateDateValidators(validation, startValue);
    } else {
      this.updateDateValidators(true, this.today);
    }
    (this.form.get('startDate') as AbstractControl).updateValueAndValidity();
    (this.form.get('endDateDefinition') as AbstractControl).updateValueAndValidity();
  }

  updateDateValidators(endDateDefinition?: boolean, startDate?: Date) {
    this.form.get('endDateDefinition').setValue(endDateDefinition);
    this.form.get('startDate').setValue(startDate);
  }

  private enableDisableFormFields(selectedOperation: 'park' | 'unpark') {
    if (selectedOperation === 'unpark') {
      this.disableStartDate = true;
      this.disableEndDate = true;
      this.form.controls.parkIndefinitely.disable();
      this.form.controls.parkComment.disable();
    } else {
      this.disableStartDate = false;
      this.disableEndDate = false;
      this.form.controls.parkIndefinitely.enable();
      this.form.controls.parkComment.enable();
    }
  }

  save() {
    const isParking = this.form.controls.parkOperation.value === 'park';
    if (this.form.valid && (this.selectedDates?.start || !isParking)) {
      this.isSaving = true;
      const parkIndefinitely = this.form.controls.parkIndefinitely.value;

      const parkingAlarms: ParkingOperationDto = {
        isParking,
        startDate: isParking ? this.selectedDates?.start : null,
        endDate: !isParking || parkIndefinitely ? null : this.selectedDates?.end,
        operationTime: this._dateHelperService.convertDateToUTC(new Date()),
        profileInfoIds: this.selectedProfiles,
        parkDescription: this.form.controls.parkComment.value,
        userCode: this._authenticationService?.userCode?.split('@')[0],
      };
      this._alarmsConfigurationService.setAlarmsParking(parkingAlarms).subscribe({
        next: (value) => {
          if (!value) {
            this._dialogService.showTranslatedMessage(
              new WlmDialogSettings({
                translateKey: `${this.T_SCOPE}.park-save-error`,
                icon: 'error',
              })
            );
          }
          this._cacheService.clearContainsInUrl('alarm/configuration').then(() => {
            this.isSaving = false;
            this._dialogRef.close(this.form.controls.parkOperation.value);
          });
        },
        error: (error) => {
          this._dialogService.showTranslatedMessageInSnackBar(
            new WlmDialogSettings({
              translateKey: `${this.T_SCOPE}.messages.save-error`,
              icon: 'error',
            })
          );
          this.isSaving = false;
        },
      });
    }
  }

  close() {
    this._dialogRef.close();
  }

  onDateRangeChanged(dateRange: DateRange) {
    this.selectedDates = dateRange;
    this.form.controls.startDate.setValue(dateRange.start);
    this.validateDatesCompletion();
  }
}
