export class GisElementDefinitionTypesDto {
  networkElementTypeId: number;
  networkElementSubTypeId?: number;
  gisLayerId: number;
  layerNumber: number;

  constructor(init: Partial<GisElementDefinitionTypesDto>) {
    Object.assign(this, init);
  }
}
