import { Inject, Injectable, Injector, Optional } from '@angular/core';
import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DynamicSettingsService } from '../../shared/config/dynamic-settings.service';
import { UtilsHelperService } from '../../shared/helpers/utils-helper.service';
import { SelectOption } from '../../shared/model/shared/select-option';
import { customTrace } from '../../shared/rxjs/operators/custom-trace.operator';

import { CustomFieldTypes } from '../models/custom-field-types';
import { DynamicFormSettings } from '../models/dynamic-form-settings';
import { FieldDataType } from '../models/field-data-type';
import { FieldDefinition } from '../models/field-definition';
import { FieldDisplayType } from '../models/field-display-type';
import { FieldInputTypes } from '../models/field-input-types';
import { FieldParseDependencies } from '../models/field-parse-dependencies';
import { FieldValidatorApiOptions } from '../models/field-validation-api-options';
import { FieldValidationUI } from '../models/field-validation-ui';
import { FieldValidationUIOptions } from '../models/field-validation-ui-options';
import {
  FieldValidatorParams,
  FieldValidatorValueModes,
} from '../models/field-validator-value-modes';
import { BaseFormLabelOverwriteService } from './base-form-label-overwrite.service';
import { DynamicFormHelperService } from './dynamic-form-helper.service';
import { DynamicFormParserService } from './dynamic-form-parser.service';
import { DynamicFormValidatorsService } from './dynamic-form-validators.service';

type ExpressionProperties =
  | string
  | Observable<any>
  | ((model: any, formState: any, field?: FormlyFieldConfig) => any);

@Injectable()
export class FormlyFormParserService extends DynamicFormParserService {
  private readonly _allowedModes = [FieldValidatorValueModes.Fixed, FieldValidatorValueModes.Model];
  private readonly _allowedParams = [FieldValidatorParams.Strict];

  constructor(
    injector: Injector,
    private _helperService: DynamicFormHelperService,
    private _dynamicSettingsService: DynamicSettingsService,
    private _translate: TranslateService,
    private _utilsHelperService: UtilsHelperService,
    @Optional()
    @Inject(BaseFormLabelOverwriteService)
    private _labelOverwriteService: BaseFormLabelOverwriteService
  ) {
    super(injector);
  }

  parseForm(
    fieldDefinitions: { [key: string]: FieldDefinition },
    dependencies: FieldParseDependencies,
    settings: DynamicFormSettings
  ): Observable<FormlyFieldConfig[]> {
    let definitions = [];

    for (let key in fieldDefinitions) {
      const definition = this._helperService.clone(fieldDefinitions[key]);
      definitions.push(definition);
    }

    definitions = this._helperService.sortObjects(definitions, (item) => Number(item.order));

    if (!definitions.length) {
      return of([]);
    }

    const results$: Observable<FormlyFieldConfig>[] = definitions.map((definition) =>
      this.parseFieldDefinition(definition, dependencies, settings)
    );

    return forkJoin(results$).pipe(
      map((fields) => {
        return [
          {
            fieldGroupClassName: 'container-fluid',
            fieldGroup: [
              {
                fieldGroupClassName: 'row',
                fieldGroup: fields,
              },
            ],
          },
        ] as FormlyFieldConfig[];
      })
    );
  }

  private parseFieldDefinition(
    source: FieldDefinition,
    dependencies: FieldParseDependencies,
    settings: DynamicFormSettings
  ): Observable<FormlyFieldConfig> {
    return this.parseTemplateOptions(source, dependencies, settings).pipe(
      map((templateOptions) => {
        const config: FormlyFieldConfig = {};
        config.key = source.name;
        config.type = this.parseInputType(source);
        config.className = this.parseClassName(source);

        config.validation = {
          show: true,
        };

        config.templateOptions = templateOptions;
        config.validators = this.parseValidators(source);

        config.expressionProperties = this.parseExpressionProperties(
          source,
          config,
          dependencies,
          settings
        );

        if (typeof source.hideExpression !== 'undefined') {
          config.hideExpression = source.hideExpression;
        }

        return config;
      })
    );
  }

  private getGlobalValue$(source: FieldDefinition): Observable<string> {
    if (!source.globalDataSource || !this._labelOverwriteService) {
      return of(null);
    }

    return this._labelOverwriteService.apply(source, '');
  }

  /**
   * Pôstprocess the validations that were of type "expression".
   * If possible, implement them directly with expresionProperties.
   */
  private parseExpressionProperties(
    source: FieldDefinition,
    config: FormlyFieldConfig,
    dependencies: FieldParseDependencies,
    settings: DynamicFormSettings
  ): {
    [key: string]: ExpressionProperties;
  } {
    let expressions = config.expressionProperties || {};
    expressions = { ...expressions, ...(source.expressions || {}) };

    expressions['templateOptions.label'] = this.parseTranslateLabel(source, dependencies, settings);

    if (typeof source.requiredExpression !== 'undefined') {
      expressions['templateOptions.required'] = this.preprocessExpression(
        source.requiredExpression
      );
    }

    if (typeof source.readOnlyExpression !== 'undefined') {
      expressions['templateOptions.disabled'] = this.preprocessExpression(
        source.readOnlyExpression
      );
    }

    return expressions;
  }

  private preprocessExpression(expression: string): string {
    const result = this._helperService.strReplaceAll(expression, 'Model', 'model');
    return result;
  }

  private parseClassName(source: FieldDefinition): string {
    return source.display === FieldDisplayType.Half ? 'col-6' : 'col-12';
  }

  private parseTemplateOptions(
    source: FieldDefinition,
    dependencies: FieldParseDependencies,
    settings: DynamicFormSettings
  ): Observable<FormlyTemplateOptions> {
    // UOM services has cached results so it will not trigger an API call each time it is called

    const templateOptions: FormlyTemplateOptions = {};

    const subType = this.parseInputSubtype(source);
    if (subType) {
      templateOptions.type = subType;
    }

    templateOptions.label = source.labelKey;
    templateOptions._fullLabelKey = this.parseFullLabelKey(source, settings);
    templateOptions.required = source.required;
    templateOptions.params = source.input.params;

    const optionsUri = source.input.optionsUri;

    if (source.input.type === FieldInputTypes.Selector || !!optionsUri) {
      templateOptions.options = this.parseSelectOptions(source, dependencies);
      if (optionsUri) {
        templateOptions._optionsUri = optionsUri;
      }
    }

    // We have to store the observable here because we cannot resolve it in this moment.
    templateOptions._globalValue$ = this.getGlobalValue$(source);

    templateOptions._source = source;

    return of(templateOptions);
  }

  private parseFullLabelKey(source: FieldDefinition, settings: DynamicFormSettings): string {
    const fullLabelKey = [settings.attributesLabelsPath, source.labelKey].join('.');
    return fullLabelKey;
  }

  private parseTranslateLabel(
    source: FieldDefinition,
    dependencies: FieldParseDependencies,
    settings: DynamicFormSettings
  ): Observable<string> {
    const label = source.labelKey;

    if (!label) {
      return of(source.name);
    }

    const fullLabelKey = this.parseFullLabelKey(source, settings);

    const translateLabel$ = this._utilsHelperService.currentCurrency().pipe(
      switchMap((currency) => {
        return this._translate.get(fullLabelKey, {
          currency,
        });
      }),
      // If the labelKey is not found in the translation JSONs, it means it already contains the translated label from the API.
      switchMap((translatedLabel) => {
        if (translatedLabel !== fullLabelKey) {
          return of(translatedLabel);
        }

        if (settings.labelOverwrites) {
          for (let overwrite of settings.labelOverwrites) {
            // We only apply the first one that matches.
            if (this.matchRegex(overwrite.onRegex, label)) {
              return this._translate.get(overwrite.useKey, {
                [overwrite.labelParam]: label,
              });
            }
          }
        }

        return of(label);
      }),
      map((translatedLabel) => {
        const uomLabel = this._helperService.parseUnitLabel(source, dependencies);
        if (uomLabel) {
          const withUom = `${translatedLabel} [${uomLabel}]`;
          return withUom;
        }
        return translatedLabel;
      })
    );

    return translateLabel$;
  }

  private parseSelectOptions(
    source: FieldDefinition,
    dependencies: FieldParseDependencies
  ): SelectOption<string>[] | Observable<SelectOption<string>[]> {
    const convert = (value: string, label: string) => {
      let convertedValue = this._helperService.convertToDataTypes(value, source);

      if (this._helperService.fieldHasUoM(source)) {
        convertedValue = this._helperService.toUoM(source, convertedValue, dependencies);
      }

      const option = new SelectOption<string>({ value: convertedValue, label });
      return option;
    };

    const optionsUri = source.input.optionsUri;
    const avoidCache = source.input.avoidCache;
    const params = source.input.params;
    if (typeof optionsUri !== 'undefined') {
      return this._dynamicSettingsService.getSelectorsOptions(optionsUri, params, avoidCache).pipe(
        map((options) => {
          let optionsMapped = options.map((option) => convert(option.value, option.label));
          let nullOption = optionsMapped.find((f) => f.value === 'null');

          if (nullOption) {
            optionsMapped = optionsMapped.filter((f) => f.value !== 'null');
            optionsMapped.push(nullOption);
          }

          return optionsMapped;
        }),
        customTrace('Dynamic Form - Map Form Attributes Select Options')
      );
    }
    const mappedOptions = Object.entries(source.input.options).map(([value, label]) =>
      convert(value, label)
    );
    return mappedOptions;
  }

  private parseInputType(source: FieldDefinition): string {
    let nativeType = source.input.type.toLowerCase();
    // TODO: Override in API
    if (nativeType === 'numeric') {
      nativeType = 'input';
    }
    if (nativeType === FieldInputTypes.Selector || nativeType === 'selector') {
      nativeType = 'select';
    }

    if (nativeType === 'input' && source.dataType === FieldDataType.Int) {
      nativeType = CustomFieldTypes.IntegerInput;
    }

    if (nativeType === 'blank') {
      nativeType = CustomFieldTypes.Blank;
    }

    return nativeType;
  }

  private parseInputSubtype(source: FieldDefinition): string | null {
    const nativeType = source.input.type.toLowerCase();
    let nativeSubtype = source.input.subtype?.toLowerCase();
    // TODO: Override in API
    if (nativeType === 'numeric') {
      nativeSubtype = 'number';
    }
    return nativeSubtype;
  }

  /**
   * Translate from API validators to frontend validators.
   * For uniformity, all validators with all modes will be processed the same way, although some model validators
   * could go in the template options.
   */
  parseValidators(source: FieldDefinition): { validation: FieldValidationUI[] } {
    const apiValidators = source.validators;
    const frontValidators = [];

    for (let apiValidator of Object.values(apiValidators)) {
      const { options, type } = apiValidator;

      const modes = this.getValidatorModes(apiValidator);
      const params = this.getValidatorParams(apiValidator);

      const uiType = DynamicFormValidatorsService.apiToUITypes.get(type);

      const validationUiOptions = modes.map(
        (mode) =>
          new FieldValidationUIOptions({
            mode,
            data: options[mode],
            params: this.getFieldValidationParams(options, params, mode),
          })
      );

      frontValidators.push(
        new FieldValidationUI({
          name: uiType,
          options: validationUiOptions,
        })
      );
    }

    const result = {
      validation: frontValidators,
    };
    return result;
  }

  private getValidatorParams(apiValidator: FieldValidatorApiOptions): FieldValidatorParams[] {
    const { options } = apiValidator;
    const selectedParams = this._allowedParams.filter(
      (allowedParam) => typeof options[allowedParam] !== 'undefined'
    );

    return selectedParams;
  }

  private getFieldValidationParams(
    options: { [mode: string]: string }[],
    params: FieldValidatorParams[],
    mode: FieldValidatorValueModes
  ): FieldValidatorParams[] {
    let validationParams = [];

    params.forEach((param) => {
      if (options[param]?.includes(mode)) {
        validationParams.push(param);
      }
    });

    return validationParams;
  }

  private getValidatorModes(apiValidator: FieldValidatorApiOptions): FieldValidatorValueModes[] {
    const { options } = apiValidator;
    const selectedModes = this._allowedModes.filter(
      (allowedMode) => typeof options[allowedMode] !== 'undefined'
    );
    return selectedModes;
  }

  private matchRegex(regex: string, text: string): boolean {
    const matches = new RegExp(regex).exec(text);
    return matches && matches.length && matches[0] === text;
  }
}
