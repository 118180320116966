import { Injectable, Injector } from '@angular/core';
import { IHierarchyElementTypes } from '@common-modules/dependencies/he/hierarchy.constants';
import { Observable, forkJoin, map, shareReplay, take } from 'rxjs';
import { BaseService } from '../../base.service';
import { AlgorithmAttributesIds } from '../../model/algorithm/algorithm-attributes-ids';
import { GlobalsService } from '../../services/globals.service';

@Injectable()
export class TitleMethodsService extends BaseService {
  defaultFamily = '07cf5f7e-a084-4383-a73d-09eda922631a';
  private heLevelCache$: Observable<{ [key: string]: string }>;

  constructor(injector: Injector, private _globalService: GlobalsService) {
    super(injector);
  }

  getProcessedTitleValue(methodName: string): Observable<{ [key: string]: string }> {
    return this[methodName]();
  }

  getHELevelForAbnormalConsumptionByZone(): Observable<{ [key: string]: string }> {
    if (!this.heLevelCache$) {
      const algorithmAttributes$ = this._globalService.getAlgorithmAttributes();
      const heTypes$ = this._globalService.getHierarchyElementTypes(this.defaultFamily);

      this.heLevelCache$ = forkJoin([algorithmAttributes$, heTypes$]).pipe(
        take(1),
        map(([algorithmAttributes, heTypes]) => {
          const levelId = algorithmAttributes.find(
            (f) =>
              f.algorithmAttributeId === AlgorithmAttributesIds.AbnormalConsumptionHierarchyLevel
          )?.algorithmAttributeValue;

          if (!levelId) {
            return IHierarchyElementTypes.DMA;
          }

          return heTypes.find(
            (x) => x.hierarchyElementTypeId.toLowerCase() === levelId.toLowerCase()
          ).hierarchyElementTypeName;
        }),
        map((result) => ({ '0': result })),
        shareReplay(1)
      );
    }

    return this.heLevelCache$;
  }
}
