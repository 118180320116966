<div *ngIf="gridFilters" class="grid-container">
  <div class="filter-buttons-container">
    <button
      mat-icon-button
      class="button-position"
      [disabled]="!userIdentityGrid?.canClearFilters()"
      (click)="userIdentityGrid?.clearFilters()"
      [matTooltip]="'common.clear-grid-filter' | translate | lowercase"
    >
      <mat-icon class="material-icons-outlined" svgIcon="reset-grid"></mat-icon>
    </button>
    <button
      mat-icon-button
      *hasPerm="['WLMAdministrationCrud', 'u']"
      [matTooltip]="T_SCOPE + '.add-identity-user' | translate | lowercase"
      onclick="this.blur()"
      (click)="assignIdentityUser()"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      mat-icon-button
      *hasPerm="['WLMAdministrationCrud', 'u']"
      [matTooltip]="T_SCOPE + '.remove-identity-user' | translate | lowercase"
      [disabled]="!selectedUsers?.length"
      (click)="removeAssignedUser()"
    >
      <mat-icon svgIcon="delete"></mat-icon>
    </button>
  </div>
  <wlm-generic-grid
    #userIdentityGrid
    class="users-grid"
    [additionalFilters]="additionalFilters"
    [gridSettings]="gridSettings"
    [gridFiltersForBinding]="gridFilters"
    [(selectedItems)]="selectedUsers"
    [removeSelection$]="removeSelection$"
    [subscriptionTag]="identityUsersGridName"
    [showSelectedList]="true"
    [showSelectedItems]="false"
  ></wlm-generic-grid>
</div>
