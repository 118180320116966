import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType, FormlyFieldProps } from '@ngx-formly/material/form-field';

interface InputProps extends FormlyFieldProps {}

@Component({
  selector: 'wlm-input-with-icon',
  templateUrl: './input-with-icon.component.html',
  styleUrls: ['./input-with-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputWithIconComponent extends FieldType<FieldTypeConfig<InputProps>> {
  readonly iconSize = 'md';

  get type() {
    return this.props.type || 'text';
  }

  get icon() {
    return (this.props as any).icon || 'info';
  }

  get iconColor() {
    return (this.props as any).iconColor;
  }

  get iconMessageKey() {
    return this.params.iconMessageKey;
  }

  get params() {
    const params = (this.field.props as any).params;
    return params;
  }
}
