import { Injectable } from '@angular/core';
import { TimeAggregationEnum } from '../model/algorithm/time-aggregation.enum';
import { DateRange } from '../model/date/date-range';
import { LogService } from '../wlm-log/log.service';
import { ArrayHelperService } from './array-helper.service';
import { ObjectHelperService } from './object-helper.service';

@Injectable()
export class DateHelperService {
  readonly iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
  readonly currentRangeStartYear = 1800;
  readonly currentRangeEndYear = 2200;
  readonly currentRangeStart = `${this.currentRangeStartYear}-01-01T00:00:00`;
  readonly currentRangeEnd = `${this.currentRangeEndYear}-01-01T00:00:00`;

  constructor(
    private _logService: LogService,
    private _objectHelper: ObjectHelperService,
    private _arrayHelper: ArrayHelperService
  ) { }

  truncateDate(date: Date): Date {
    return this.setTime(date);
  }

  setTime(date: Date, hours = 0, minutes = 0, seconds = 0, milliseconds = 0): Date {
    if (!date) {
      return date;
    }
    const newDate = new Date(date);
    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    newDate.setSeconds(seconds);
    newDate.setMilliseconds(milliseconds);

    return newDate;
  }

  ensureDateObject = (date: any): Date => {
    if (!date) {
      return date;
    }
    if (this.isDateObject(date)) {
      return date;
    }
    if (typeof date === 'string') {
      return this.fromApiFormat(date);
    }
    if (typeof date.toDate !== 'undefined') {
      return date.toDate();
    }
  };

  /**
   * Get a default start date for a date range.
   */
  getDefaultStartDate(offset: number) {
    const truncateStart = this.truncateDate(new Date());
    truncateStart.setMonth(truncateStart.getMonth() - offset);
    return truncateStart;
  }

  /**
   * Get a default end date for a date range.
   */
  getDefaultEndDate(useUTC = false) {
    const truncateDate = this.truncateDate(new Date());
    const truncateEndDate = useUTC
      ? new Date(
        Date.UTC(
          truncateDate.getUTCFullYear(),
          truncateDate.getUTCMonth(),
          truncateDate.getUTCDate()
        )
      )
      : truncateDate;
    return truncateEndDate;
  }

  /**
   * Get a date with number of month substracted.
   */
  getDateByMonthAgo(months: number) {
    const truncateStart = this.truncateDate(new Date());
    truncateStart.setMonth(truncateStart.getMonth() - months);
    return truncateStart;
  }

  /**
   * Get a date with number of month substracted.
   */
  getDateByDaysAgo(days: number) {
    const truncateStart = this.truncateDate(new Date());
    truncateStart.setDate(truncateStart.getDate() - days);
    return truncateStart;
  }

  /**
   * Get a date with number of years substracted.
   */
  getDateByYearsAgo(year: number) {
    const truncateStart = this.truncateDate(new Date());
    truncateStart.setFullYear(truncateStart.getFullYear() - year);
    return truncateStart;
  }

  /**
   * Create a default date range.
   */
  createDefaultDateRange(offsetStartDate = 3): DateRange {
    const dateRange = new DateRange(
      this.getDefaultStartDate(offsetStartDate),
      this.getDefaultEndDate()
    );
    return dateRange;
  }

  createDefaultDateRangeByDays(offsetStartDateDays = 30): DateRange {
    const endDate = this.getDefaultEndDate();
    const startDate = this.addDays(endDate, -offsetStartDateDays);
    const dateRange = new DateRange(startDate, endDate);
    return dateRange;
  }

  /**
   * Converts a date to a UTC date, in a standard ISO format.
   * @param date A local time Date object.
   * @returns An UTC date, formatted as an ISO string (2011-10-05T14:48:00.000Z).
   */
  toApiFormat(date: Date): string {
    if (date && typeof date.toISOString === 'undefined') {
      this._logService.error({ msg: 'The method toISOString is not supported in this browser.' });
      return null;
    }
    let result = date ? date.toISOString() : null; // Also converts to UTC.

    if (result) {
      result = result.replace(/\.\d\d\dZ/, 'Z');
    }
    return result;
  }

  ensureApiFormat(date: Date | string): string | null {
    if (date === null || typeof date === 'undefined') {
      return date as null;
    }
    const result = this.isDateObject(date) ? this.toApiFormat(date as Date) : (date as string);
    return result;
  }

  toApiFormatNoUTC(date: Date): string {
    if (!date || !this.isDateObject(date)) {
      return null;
    }
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  /**
   * Creates a local Date object from an API formatted string.
   * @param date An UTC date, formatted as an ISO string (2011-10-05, 2011-10-05T14:48:00.000Z, 2011-10-05T14:48:00Z).
   * @returns A local time Date object.
   */
  fromApiFormat = (utcDate: string, isUTC = true): Date => {
    if (!utcDate) {
      return null;
    }

    // This will be useful until all the application uses the date interceptor.
    if (this.isDateObject(utcDate)) {
      return utcDate as any;
    }

    if (isUTC) {
      // Ensure the date will be parsed as if it was UTC.
      if (!utcDate.includes('Z') && !utcDate.includes('UTC')) {
        utcDate = utcDate + 'Z'; // The presence of Z will ensure the format is UTC
      }
    } else {
      // Ensure the date will be parsed as local.
      utcDate = utcDate.replace('Z', '');
      utcDate = utcDate.replace('UTC', '');
    }

    const result = new Date(utcDate); // Date constructor automatically creates local dates.
    return result;
  };

  /**
   * Get duration in hours between two dates
   */
  getDurationInHours(startDate: Date, endDate: Date): number {
    const hours = (endDate.valueOf() - startDate.valueOf()) / (1000 * 3600);
    return hours;
  }

  /**
   * Add a number of days to the current received as parameter
   */
  addDays(date: Date, days: number): Date {
    const newDate = new Date(date.getTime());
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  /**
   * Add a number of seconds to the current received as parameter
   */
  addSeconds(date: Date, seconds: number): Date {
    const newDate = new Date(date.getTime());
    newDate.setSeconds(newDate.getSeconds() + seconds);
    return newDate;
  }

  /**
   * Get minimum date from list of dates.
   * @param dates list of dates
   * @returns minimum date
   */
  getMinDate(dates: Date[]): Date {
    if (!dates || dates.length === 0) {
      return null;
    }
    return this.sortDates(dates)[0];
  }

  /**
   * Get maximun date from list of dates.
   * @param dates list of dates
   * @returns maximun date
   */
  getMaxDate(dates: Date[]): Date {
    if (!dates || dates.length === 0) {
      return null;
    }
    return this.sortDates(dates)[dates.length - 1];
  }

  /**
   * Sort an array of dates from older to newer.
   */
  sortDates(dates: Date[]): Date[] {
    if (!dates) {
      return dates;
    }
    return dates.sort((a, b) => +new Date(a) - +new Date(b));
  }

  /**
   * Given a Date, returns a Date in local format but with the UTC values
   */
  convertDateToUTC(date: Date): Date {
    if (!date) {
      return date;
    }

    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    );
  }

  getOnlyDateToLocal(isoDateString: string): Date {
    return this.convertDateToUTC(new Date(isoDateString));
  }

  isDateObject = (date) =>
    date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);

  //Calculate the number of days between a start and end dates.
  daysBetween(start, end): number {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }

  /**
   * Creates an array of dates which goes from (lastDate - daysAmount) to lastDate.
   */
  datesUntilLast(lastDate, daysAmount): Date[] {
    const result = [];
    for (let daysToSubtract = 0; daysToSubtract < daysAmount; daysToSubtract++) {
      result.push(this.addDays(lastDate, -daysToSubtract));
    }
    return result;
  }

  /**
   * Get a date object that, when converted to ISO string,
   * will output exacly the same day as when it is in the local Date object.
   */
  getDateNoTime(inputDate): Date {
    let date = this.ensureDateObject(inputDate);
    date = this.setTime(date);
    date = this.applyInvertedTimezone(date);
    return date;
  }

  /**
   * Normal behavior: If date is 10/10/200 14:00 in local GMT+2, when converting to UTC,
   * it would be 10/10/200 12:00. This subtracted 2 hours when CMT is +2, but this method does
   * the opposite: in this case, add 2 hours to the date: 10/10/200 16:00.
   * This enables us to make toISOString output exactly 10/10/200 14:00 instead of 10/10/200 12:00.
   */
  applyInvertedTimezone(date: Date): Date {
    var timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - timeOffsetInMS);
    return date;
  }

  equals(a: Date, b: Date): boolean {
    return a.getTime() === b.getTime();
  }

  toApiFormatRecursive<T>(data, noUtcExceptions: string[]): T {
    const cloned = this._objectHelper.clone(data);
    // Get all paths to date properties.
    const datePaths = this.findDateObjectsRecursive(data);
    datePaths.forEach((path) => {
      const node = this._objectHelper.deepGet(data, path);
      // Ensure the current path leads to a date.
      if (this.isDateObject(node)) {
        const noUtc =
          noUtcExceptions.findIndex((utcPath) =>
            this.comparePropertyPathNoIndexes(utcPath, path)
          ) !== -1;
        const formatted = noUtc ? this.toApiFormatNoUTC(node) : this.toApiFormat(node);
        // Set the formatted version.
        this._objectHelper.deepSet(cloned, path, formatted);
      }
    });
    return cloned;
  }

  fromApiFormatRecursive<T>(data, keepStringExceptions: string[]): T {
    const cloned = this._objectHelper.clone(data);
    // Get all paths to date properties.
    const strPaths = this.findDateStringsRecursive(data);
    strPaths.forEach((path) => {
      const node = this._objectHelper.deepGet(data, path);
      // Ensure the current path leads to a date.
      if (typeof node === 'string') {
        const keepString =
          keepStringExceptions.findIndex((ex) => this.comparePropertyPathNoIndexes(ex, path)) !==
          -1;
        const formatted = keepString ? node : this.fromApiFormat(node);
        // Set the formatted version.
        this._objectHelper.deepSet(cloned, path, formatted);
      }
    });
    return cloned;
  }

  excludePropertyPaths(paths: string[], exceptions: string[]): string[] {
    // If exceptions have "foo[].bar", all foo[1].bar, foo[2].bar must be excluded.
    // Convert all [1], [2] into [], so they match the exceptions.
    const pathsNoIndexes = paths.map((path) => path.replace(/\[+\d\]/g, '[]'));
    const results = pathsNoIndexes.filter(
      (path) => exceptions.findIndex((except) => except !== path) === -1
    );
    return results;
  }

  comparePropertyPathNoIndexes(path1: string, path2: string): boolean {
    // Remove indexes, foo[1].bar becomes foo[].bar
    const noIndexRegex = /\[\d+\]/g;
    path1 = path1.replace(noIndexRegex, '[]');
    path2 = path2.replace(noIndexRegex, '[]');
    return path1 === path2;
  }

  /**
   * Recursively find all dates inside an object / array, and return its paths.
   */
  findDateObjectsRecursive(data): string[] {
    const paths = [];
    const checkDateObject = (node, path: string) => {
      if (this.isDateObject(node)) {
        paths.push(path);
      }
    };
    this._objectHelper.applyRecursive(data, checkDateObject);
    return paths;
  }

  /**
   * Recursively find all ISO string dates inside an object / array, and return its paths.
   */
  findDateStringsRecursive(data): string[] {
    const paths = [];
    const checkDateString = (node, path: string) => {
      if (typeof node === 'string' && this.dateStringIsIso(node)) {
        paths.push(path);
      }
    };
    this._objectHelper.applyRecursive(data, checkDateString);
    return paths;
  }

  dateStringIsIso(date: string): boolean {
    if (!date) {
      return false;
    }

    return this.iso8601.test(date);
  }

  /**
   * Converts a date stored in UTC to local Date
   */
  convertUTCStoredDateToLocal(date: Date): Date {
    const year = date.getFullYear().toString().padStart(4, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const min = date.getMinutes().toString().padStart(2, '0');
    const sec = date.getSeconds().toString().padStart(2, '0');
    const mill = date.getMilliseconds().toString().padStart(3, '0');

    return new Date(`${year}-${month}-${day}T${hour}:${min}:${sec}.${mill}Z`);
  }

  checkDateCollision(dateRange1: DateRange, dateRange2: DateRange) {
    return dateRange1.end > dateRange2.start && dateRange1.start < dateRange2.end;
  }

  datesBetween = (range: DateRange, min: Date = undefined, max: Date = undefined): Date[] => {
    let start = new Date(range.start);
    let end = new Date(range.end);

    if (typeof min !== 'undefined') {
      start = this.maxDates(start, min);
    }

    if (typeof max !== 'undefined') {
      end = this.minDates(end, max);
    }

    const results = [];
    for (let currentDate = start; currentDate < end; currentDate = this.addDays(currentDate, 1)) {
      results.push(new Date(currentDate));
    }
    return results;
  };

  /**
   * Calculate all dates that are involved in all of the specified date ranges.
   * Example: (1, 4), (8, 10) => [1, 2, 3, 4, 8, 9, 10]
   */
  datesInRanges = (ranges: DateRange[]): Date[] => {
    let dates = ranges.reduce(
      (total, currentRange) => total.concat(this.datesBetween(currentRange)),
      []
    );
    dates = this._arrayHelper.onlyUnique(dates);
    return dates;
  };

  isCurrentStartDate = (date: Date) => {
    const result = this.checkYear(date, this.currentRangeStartYear);
    return result;
  };

  isCurrentEndDate = (date: Date) => {
    const result = this.checkYear(date, this.currentRangeEndYear);
    return result;
  };

  maxDates = (date1: Date, date2: Date) => new Date(Math.max(+date1, +date2));
  minDates = (date1: Date, date2: Date) => new Date(Math.min(+date1, +date2));

  getCurrentFiscalYear(fiscalYearStartDay: number, fiscalYearStartMonth: number): number {
    const date = new Date();
    const fiscalDate = new Date(date.getFullYear(), fiscalYearStartMonth - 1, fiscalYearStartDay);

    if (date >= fiscalDate) {
      return date.getFullYear();
    }

    return date.getFullYear() - 1;
  }

  roundDateToNearestUpperMinute(date: Date, timeLapse: number): Date {
    let roundedDate = new Date(date.getTime());

    const mod = roundedDate.getMinutes() % timeLapse;
    if (mod !== 0) {
      const minutesToAdd = timeLapse - mod;
      roundedDate = new Date(roundedDate.getTime() + minutesToAdd * 60 * 1000);
    }

    return new Date(
      roundedDate.getTime() - roundedDate.getSeconds() * 1000 - roundedDate.getMilliseconds()
    );
  }

  resetSecondsAndMilliseconds(date: Date): Date {
    const newDate = new Date(date.getTime());
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    return newDate;
  }

  /**
   * Find the date in an array which is closer to a given date.
   * Returns both the value and the index.
   */
  getClosestDate(currentDate: Date | number, dates: (Date | number)[]) {
    const currentDateMs = +currentDate;
    let closestDateIndex;
    let closestDate;
    let minDiff = Infinity;

    dates.forEach((date, index) => {
      const diff = Math.abs(+date - currentDateMs);
      if (diff < minDiff) {
        minDiff = diff;
        closestDate = date;
        closestDateIndex = index;
      }
    });

    return {
      date: closestDate,
      index: closestDateIndex,
    };
  }

  getDateRangeFromRollingParams(
    timeAggregation: TimeAggregationEnum,
    offset: number,
    window: number
  ): DateRange {
    let days = 1;

    switch (timeAggregation) {
      case TimeAggregationEnum.Daily:
        days = 1;
        break;

      case TimeAggregationEnum.Weekly:
        days = 7;
        break;

      case TimeAggregationEnum.Monthly:
        days = 30;
        break;

      case TimeAggregationEnum.Yearly:
        days = 365;
        break;

      default:
        return null;
    }

    const startDate = this.getDateByDaysAgo(offset * days);
    const endDate = this.addDays(startDate, window * days - 1);

    return new DateRange(startDate, endDate);
  }

  private checkYear = (date: Date, year: number) => {
    if (!date) {
      return false;
    }

    const dateObj = this.ensureDateObject(date);
    const result =
      dateObj?.getFullYear() === year || this.convertDateToUTC(dateObj)?.getFullYear() === year;
    return result;
  };
}
