<mat-chip-grid #chipList>
  <mat-chip-row
    *ngFor="let option of selectedOptions"
    (click)="removeChip($event, option)"
    [disabled]="readonlyRows"
  >
    {{ option.label }}
    <mat-icon matChipRemove (click)="removeChip($event, option)">cancel</mat-icon>
  </mat-chip-row>

  <input
    matInput
    type="text"
    [matAutocomplete]="auto"
    [formControl]="selectControl"
    [matChipInputFor]="chipList"
    (keydown)="$event.preventDefault()"
  />
</mat-chip-grid>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
  <mat-option
    *ngFor="let option of filteredOptions$ | async"
    (click)="optionClicked($event, option)"
  >
    <div [class]="isSelected(option) ? 'selected' : ''">
      <span class="option-text">{{ option.label }}</span>
    </div>
  </mat-option>
</mat-autocomplete>
