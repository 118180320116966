import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
// prettier-ignore
import { HttpCacheService } from '@common-modules/cache/http-cache/http-cache.service';
import { NeConfigObservableService } from '@common-modules/dynamic-layout/state/ne-config/ne-config-observable.service';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { PendingChanges } from '@common-modules/shared/pending-changes/models/pending-changes';
import { IPendingChangesEmitter } from '@common-modules/shared/pending-changes/models/pending-changes-emitter';
import { PendingChangesManagerService } from '@common-modules/shared/pending-changes/services/pending-changes-manager.service';
import { customTrace } from '@common-modules/shared/rxjs/operators/custom-trace.operator';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { DynamicFormAdditionalSettings } from '../../models/dynamic-form-additional-settings';
import { DynamicFormSave } from '../../models/dynamic-form-save';
import { DynamicFormSaveMetadata } from '../../models/dynamic-form-save-metadata';
import { DynamicFormSettings } from '../../models/dynamic-form-settings';
import { DynamicFormUIValues } from '../../models/dynamic-form-ui-values';
import { FieldDefinition } from '../../models/field-definition';
import { FieldParseDependencies } from '../../models/field-parse-dependencies';
import { DynamicFormHelperService } from '../../services/dynamic-form-helper.service';
import { DynamicFormParserService } from '../../services/dynamic-form-parser.service';
import { DynamicFormValidatorsService } from '../../services/dynamic-form-validators.service';
import { DynamicFormService } from '../../services/dynamic-form.service';

const COMPONENT_SELECTOR = 'wlm-dynamic-form';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, IPendingChangesEmitter {
  private _optsHelper$: Subscription;
  private _optsFormly$: Subscription;
  identifier: string;

  @Input() pageId: string;

  @Input() set settings(settings: DynamicFormSettings) {
    this._settings = settings;
    if (!this.settings) {
      return;
    }
    this.saveOnChanges = settings.saveOnChanges || this.saveOnChanges;

    // This data could be shared in a service or in Redux, but Redux already has this data in other format.
    // We could save some key structure and create a selector that combines the data.
    this._currentApiFields = this._helperService.hashApiFields([this.settings.fieldsByCategory]);

    if (this._optsHelper$) {
      this._optsHelper$?.unsubscribe();
    }

    if (this._optsFormly$) {
      this._optsFormly$?.unsubscribe();
    }

    this._optsHelper$ = this._helperService
      .buildFormDependencies(settings)
      .pipe(customTrace('Dynamic Form - Build Form Dependencies'))
      .subscribe((dependencies) => {
        this._optsHelper$ = null;

        this._formDependencies = dependencies;

        const opts$ = this._dynamicFormParserService
          .parseForm(settings.fieldsByCategory.fields, this._formDependencies, this.settings)
          .pipe(customTrace('Dynamic Form - Parse Form'))
          .subscribe((uiFields: FormlyFieldConfig[]) => {
            this.fields = uiFields;

            this._optsFormly$ = null;
          });

        this._optsFormly$ = opts$;
      });

    this.additionalOptions = {
      formState: globalUtilsHelper.clone(this.settings.formState ?? {}, true),
    };

    this.identifier = `DynamicFormComponent-${settings.fieldsByCategory?.categoryKey}`;
  }

  get settings(): DynamicFormSettings {
    return this._settings;
  }
  private _settings: DynamicFormSettings;
  @Input() apiFields: { [key: string]: FieldDefinition };
  @Input() set values(values: DynamicFormUIValues) {
    this.patchUndefined(values);
    this.model = this._helperService.clone(values);
    this._initialModel = this._helperService.clone(this.model);
  }
  @Input() additionalSettings: DynamicFormAdditionalSettings;

  @Output() formHasChanges = new EventEmitter<boolean>();

  form = new UntypedFormGroup({});
  model = {};
  fields: FormlyFieldConfig[];
  loading = false;
  saveOnChanges = false;
  isDisabled = true;
  isSameAsInitial = false;
  additionalOptions: {
    formState?: { [key: string]: any };
    fieldTransform?: any;
  };

  public get hasChanges(): boolean {
    return !this.form.pristine && this.form.valid && !this.isDisabled && !this.isSameAsInitial;
  }

  private _formDependencies: FieldParseDependencies;
  private _initialModel: any;
  private _currentApiFields: { [key: string]: FieldDefinition[] };
  private readonly _debounceTime = 1000;
  private readonly _attributeLabelsPath = 'configuration.attributes';

  constructor(
    private _dynamicFormService: DynamicFormService,
    private _dynamicFormParserService: DynamicFormParserService,
    private _helperService: DynamicFormHelperService,
    private _dialogsService: DialogService,
    private _localization: LocalizationHelperService,
    private _neObservableService: NeConfigObservableService,
    private _pendingChangesService: PendingChangesManagerService,
    private _cacheService: HttpCacheService
  ) {}

  ngOnInit(): void {
    const statusChanges$ = this.form.statusChanges.pipe(untilDestroyed(this));
    const valueChanges$ = this.form.valueChanges.pipe(untilDestroyed(this));
    const savePendingChanges$ = this._neObservableService.getSavePendingChangesAsObservable();

    statusChanges$.subscribe((status) => {
      this.isDisabled = status === 'INVALID';
    });

    statusChanges$
      .pipe(
        filter((_) => this.form.status === 'INVALID'),
        map((_) => this.getErrorsRecursive(this.form)),
        distinctUntilChanged(this._helperService.deepEqual),
        customTrace('Dynamic Form - Get Errors Recursively')
      )
      .subscribe((errors) => {
        this.updateModelErrors(errors, this.form);
        if (Object.keys(errors).length !== 0) {
          globalUtilsHelper.markAllAsTouched(this.form);
        }
      });

    valueChanges$
      .pipe(distinctUntilChanged(this._helperService.deepEqual), debounceTime(this._debounceTime))
      .subscribe((currentModel) => {
        this.triggerValidations(true);
        this.patchUndefined(currentModel);
        this.checkSameAsInitial(currentModel);

        this.setPendingChanges(this.pageId, {
          componentId: this.identifier,
          hasValidChanges: this.canSave(),
          saveFn: () => this.save(this.form.value),
        });
      });

    savePendingChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.hasChanges) {
        this.onSave(this.form.value);
      }
    });

    if (this.saveOnChanges) {
      valueChanges$.pipe(debounceTime(this._debounceTime)).subscribe({
        next: (currentModel) => {
          this.onSave(currentModel);
        },
        error: (error) => this.showErrorDialog(error),
      });
    }
  }

  onSubmit(): void {
    if (!this.saveOnChanges) {
      this.onSave(this.form.value);
    }
  }

  onReset(): void {
    this.model = this._helperService.clone(this._initialModel);
    this.isSameAsInitial = true;
  }

  canSave(): boolean {
    return this.form.valid && !this.form.pristine;
  }

  resetDisabled(): boolean {
    return this.form.pristine;
  }

  setPendingChanges(key: string, changes: PendingChanges): void {
    this._pendingChangesService.setPendingChanges(key, changes);
  }

  removePendingChangesByComponent(key: string, componentId: string): void {
    this._pendingChangesService.removePendingChangesByComponent(key, componentId);
  }

  onSave(currentModel) {
    this.save(currentModel).subscribe(() => {});
  }

  save(currentModel): Observable<boolean> {
    if (!this.form.pristine && this.form.valid && !this.isDisabled) {
      const modelDiff = this.buildModelDiff(currentModel);

      if (Object.keys(modelDiff).length) {
        this.loading = true;
        const { entityType } = this.settings;
        const elementId = this.additionalSettings.entityId;

        return this.getModelMetadata(modelDiff).pipe(
          customTrace('Dynamic Form - Get Model Metadata'),
          switchMap((metadata) => {
            const formDto = new DynamicFormSave({
              entityType,
              elementId,
              elementName: this.additionalSettings.entityName,
              values: modelDiff,
              metadata,
            });

            return this._dynamicFormService.save(
              formDto,
              this._currentApiFields,
              this._formDependencies
            );
          }),
          finalize(() => {
            this.cleanCache();
            this.loading = false;
          }),
          switchMap(() => this.loadValues()),
          customTrace('Dynamic Form - Save & Reload Values'),
          tap((model) => {
            this.patchUndefined(model);
            this.model = model;
            this._initialModel = this._helperService.clone(model);
            this.checkSameAsInitial(model);

            this._localization.get(this.settings.entityNameKey).subscribe((entityName) => {
              this._dialogsService.showTranslatedMessageInSnackBar(
                new WlmDialogSettings({
                  translateKey: 'common.messages.save',
                  params: {
                    entityName,
                  },
                  icon: 'success',
                })
              );
            });
            this.loading = false;
            this._neObservableService.notifySaveCompleted();
            this.form.updateValueAndValidity();
            this.form.markAsPristine();

            this.setPendingChanges(this.pageId, {
              componentId: this.identifier,
              hasValidChanges: this.canSave(),
              saveFn: () => this.save(this.form.value),
            });
          }),
          catchError((error) => {
            this.showErrorDialog(error);

            return of(null);
          }),
          map(() => true)
        );
      }
    } else {
      globalUtilsHelper.markAllAsTouched(this.form);
    }

    return of(true);
  }

  /**
   * Given a submodel, get the submodel with the previous properties.
   * Used for auditing in the API.
   */
  private getModelMetadata(modelDiff): Observable<DynamicFormSaveMetadata[]> {
    const fields = Object.values(this.settings.fieldsByCategory.fields);
    const modelNames = Object.keys(modelDiff);

    const metadata: DynamicFormSaveMetadata[] = [];

    return forkJoin([
      this._localization.get(this._attributeLabelsPath),
      this._localization.get(this.settings.categoriesLabelsPath),
    ]).pipe(
      take(1),
      map(([attributes, categories]) => {
        fields.forEach((field) => {
          const editedName = modelNames.find((name) => name === field.name);
          // Check that the same field control has not been included in metadata twice.
          // That could happen when we have two field implementations for the same control.
          if (editedName && !metadata.find((item) => item.name === editedName)) {
            const previous = this._helperService.clone(this._initialModel[editedName]);
            const label = attributes[field.labelKey] ?? field.labelKey;
            const category = categories[field.categoryKey] ?? field.categoryKey;

            metadata.push(
              new DynamicFormSaveMetadata({
                previous,
                label,
                category,
                name: field.name,
                identifier: field.source.identifier,
              })
            );
          }
        });
        return metadata;
      })
    );
  }

  private loadValues(): Observable<any> {
    const { entityType } = this.settings;
    const elementId = this.additionalSettings.entityId;

    return this._dynamicFormService.getValues(
      entityType,
      elementId,
      this._currentApiFields,
      this._formDependencies
    );
  }

  private cleanCache() {
    if (this.settings.cleanCachedUrl) {
      this._cacheService.clearContainsInUrl(this.settings.cleanCachedUrl).then(() => {});
    }
  }

  private checkSameAsInitial(currentFormModel): void {
    const modelDiff = this.buildModelDiff(currentFormModel);
    this.isSameAsInitial = Object.keys(modelDiff).length === 0;

    this.formHasChanges.emit(this.hasChanges);
  }

  private buildModelDiff(currentFormModel) {
    let currentFormInitialModel = null;
    if (this._initialModel) {
      // Initial model contains all the configuration properties, while currentModel only contains the properties
      // that are edited in the current form. So only compare the keys that are in the current form.
      currentFormInitialModel = Object.keys(currentFormModel).reduce((initialModel, currentKey) => {
        initialModel[currentKey] = this._helperService.clone(
          this._initialModel[currentKey] ?? null
        );
        return initialModel;
      }, {});
    }

    const modelDiff = this._helperService.deepDiff(currentFormInitialModel, currentFormModel);
    return modelDiff;
  }

  private showErrorDialog(error): void {
    this._dialogsService.showTranslatedMessageInSnackBar(
      new WlmDialogSettings({
        translateKey: 'common.messages.error',
        icon: 'error',
      })
    );
  }

  /**
   * Related to this issue https://github.com/angular/angular/issues/10530,
   * angular does not automatically gather errors of the form controls it contains.
   * So group.status can be INVALID but group.errors is null, so we have to get the errors outselves.
   */
  private getErrorsRecursive(
    group?: UntypedFormGroup | UntypedFormArray
  ): { [key: string]: ValidationErrors } | ValidationErrors[] | null {
    const errors = group instanceof UntypedFormArray ? [] : {};

    let idx = 0;
    Object.keys(group.controls).forEach((name) => {
      idx++;
      const control = group.get(name);
      if (control instanceof UntypedFormArray || control instanceof UntypedFormGroup) {
        const tmpErrors = this.getErrorsRecursive(control as UntypedFormArray | UntypedFormGroup);
        if (tmpErrors != null) {
          errors[name] = tmpErrors;
        }
      } else if (control instanceof UntypedFormControl) {
        if (control.errors == null) {
          return null;
        }
        if (group instanceof UntypedFormArray) {
          (errors as ValidationErrors[])[idx] = control.errors;
        } else {
          errors[name] = control.errors;
        }
      }
    });

    return (group instanceof UntypedFormGroup && Object.keys(errors).length === 0) ||
      (Array.isArray(errors) && errors.length === 0)
      ? null
      : errors;
  }

  /**
   * Change undefined values to null values by reference.
   */
  private patchUndefined(model): void {
    if (!model) {
      return;
    }
    Object.keys(model).forEach((key) => {
      if (typeof model[key] === 'undefined') {
        model[key] = null;
      }
    });
  }

  private triggerValidations(emitEvent: boolean): void {
    setTimeout(() => {
      Object.values(this.form.controls).forEach((control) => {
        control.updateValueAndValidity({ emitEvent, onlySelf: true });
      });
    }, 1);
  }

  private updateModelErrors = DynamicFormValidatorsService.updateModelErrors;

  ngOnDestroy(): void {
    this.removePendingChangesByComponent(this.pageId, this.identifier);
  }
}
