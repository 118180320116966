import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalizationHelperService } from '../localization/localization-helper.service';

/**
 * Translate a key or return a default text
 */

@Pipe({
  name: 'translateDefault',
})
export class TranslateDefaultPipe implements PipeTransform {
  constructor(private localization: LocalizationHelperService) { }

  transform(value: string, defaultTs: string): Observable<string> {

    return this.localization
      .getOrDefault(value, defaultTs);
  }
}
