import {
  NgxMatDateAdapter,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IsSuperUserDirective } from '@common-modules/shared/auth/is-super-user.directive';
import { SharedConstantsService } from '@common-modules/shared/constants/shared-constants.service';
import { DateRangeFilterComponent } from '@common-modules/shared/date-range-filter/date-range-filter.component';
import { CollapsibleButtonComponent } from '@common-modules/shared/grid-buttons/collapsible-button/collapsible-button.component';
import { UserNamePipe } from '@common-modules/shared/pipes/user-name.pipe';
import { AlarmsService } from '@common-modules/shared/services/alarms.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { IMaskModule } from 'angular-imask';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ColorPickerModule } from 'ngx-color-picker';
import { LeaksService } from '../dependencies/alc/services/alc.leaks.service';
import { WorkOrderService } from '../dependencies/alc/services/work-order.service';
import { InterceptClickDirective } from '../shared-component/button-toggler/intercept-click-directive';
import { AlarmTriggerPointPipe } from '../wlm-grid/pipes/alarm-trigger-point.pipe';
import { WlmSpinnerModule } from '../wlm-spinner/wlm-spinner.module';
import { HasPermissionDirective } from './auth/has-permission.directive';
import { ButtonsGroupComponent } from './buttons/buttons-group/buttons-group.component';
import { BaseFiltrableItemService } from './charts/base-filtrable-item.service';
import { ChartHelperService } from './charts/chart-helper.service';
import { EventService } from './charts/event.service';
import { FiltrableItemManagerService } from './charts/filtrable-item-manager.service';
import { CommonSharedModuleSettings } from './common-shared-module-settings';
import { CollapsibleContainerComponent } from './component/collapsible-container/collapsible-container.component';
import { EligibilityPopupComponent } from './component/eligibility-popup/eligibility-popup.component';
import { matDateFormatsProvider } from './constants/custom-providers';
import { AutocompleteStandaloneComponent } from './core/autocomplete-standalone/autocomplete-standalone.component';
import { AutoCompleteComponent } from './core/autocomplete/autocomplete.component';
import { DragListCardComponent } from './core/drag-list-card/drag-list-card.component';
import { DragListCustomComponent } from './core/drag-list-custom/drag-list-custom.component';
import { DragListVirtualComponent } from './core/drag-list-virtual/drag-list-virtual.component';
import { DragListComponent } from './core/drag-list/drag-list.component';
import { EditGridSettingsPopupComponent } from './core/edit-grid-settings-popup/edit-grid-settings-popup.component';
import { ExportAsImageButtonComponent } from './core/export-as-image-button/export-as-image-button.component';
import { ExportExcelButtonComponent } from './core/export-excel-button/export-excel-button.component';
import { ExportPdfButtonComponent } from './core/export-pdf-button/export-pdf-button.component';
import { ButtonColumnService } from './core/grid/button-column.service';
import { CellStyleService } from './core/grid/cell-style.service';
import { ColumnEnumsService } from './core/grid/column-enums.service';
import { ColumnStylePipe } from './core/grid/column-style.pipe';
import { GridSettingsService } from './core/grid/grid-settings.service';
import { TitleMethodsService } from './core/grid/title-methods.service';
import { LabelValueListModule } from './core/label-value-list/label-value-list.module';
import { ManageColumnComponent } from './core/manage-column/manage-column.component';
import { FixedSizeElementDirective } from './core/responsive/fixed-size-element.directive';
import { SelectDragListVirtualComponent } from './core/select-drag-list-virtual/select-drag-list-virtual.component';
import { DateTimeRangeErrorsComponent } from './date-time-range/date-time-range-errors/date-time-range-errors.component';
import { DateTimeRangeComponent } from './date-time-range/date-time-range.component';
import { CustomNgxDatePickerAdapter } from './dates/custom-ngx-date-picker-adapter';
import {
  DayMonthFilterComponent,
  DayMonthHeader,
} from './day-month-filter/day-month-filter.component';
import { AlertWindowComponent } from './dialogs/alert-window/alert-window.component';
import { DialogService } from './dialogs/dialogs.service';
import { ErrorWindowComponent } from './dialogs/error-window/error-window.component';
import { DaysMaskDirective } from './directives/days-mask.directive';
import { ExposeVariableDirective } from './directives/expose-variable.directive';
import { GridHorizWheelScrollDirective } from './directives/grid-horiz-wheel-scroll.directive';
import { InputValidateDirective } from './directives/input-validate.directive';
import { MaxNumberDigitsDirective } from './directives/max-number-digits.directive';
import { OnlyIntegerDirective } from './directives/only-integer.directive';
import { SimpleTestDirective } from './directives/simple-test.directive';
import { TimeMaskDirective } from './directives/time-mask.directive';
import { WRouterLinkDirective } from './directives/w-router-link.directive';
import { ExportExcelComponent } from './exports/export-excel/export-excel.component';
import { ExcelHelperService } from './exports/service/excel-helper.service';
import { GridButtonsFirstSlotDirective } from './grid-buttons/directives/grid-buttons-first-slot.directive';
import { GridButtonsSecondSlotDirective } from './grid-buttons/directives/grid-buttons-second-slot.directive';
import { GridButtonsThirdSlotDirective } from './grid-buttons/directives/grid-buttons-third-slot.directive';
import { GridButtonsInternalComponent } from './grid-buttons/grid-buttons-internal/grid-buttons-internal.component';
import { GridButtonsComponent } from './grid-buttons/grid-buttons/grid-buttons.component';
import { ArrayHelperService } from './helpers/array-helper.service';
import { ColorHelperService } from './helpers/color-helper.service';
import { DateHelperService } from './helpers/date-helper.service';
import { GridColumnHelperService } from './helpers/grid-column-helper.service';
import { GridHelperService } from './helpers/grid-helper.service';
import { NavMenuBuilderHelperService } from './helpers/navmenu-builder-helper.service';
import { StringHelperService } from './helpers/string-helper.service';
import { IconLoaderService } from './icon-loader.service';
import { IconModule } from './icon/icon.module';
import { JsonEditorComponent } from './json-editor/json-editor.component';
import { KendoSharedModule } from './kendo-shared.module';
import { LocalStorageService } from './local-storage.service';
import { ExtendDatePipe } from './localization/extend-date.pipe';
import { ExtendDecimalPipe } from './localization/extend-decimal.pipe';
import { MaterialSharedModule } from './material-shared.module';
import { NoResultsComponent } from './no-results/no-results.component';
import { GridBindingDirective } from './odata/grid-binding.directive';
import { PersistencyService } from './persistency.service';
import { CapitalizeSentencePipe } from './pipes/capitalize-sentence.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
import { PipeGridODataService } from './pipes/pipe-grid-odata.service';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TranslateDefaultPipe } from './pipes/translate-default.pipe';
import { TypeOfPipe } from './pipes/type-of.pipe';
import { PlaceholderOverlayComponent } from './placeholder-overlay/placeholder-overlay.component';
import { LogsInterfacesService } from './services/logs-interfaces.service';
import { WlmResizeObserverService } from './services/resize-observer.service';
import { RightPanelAttributesService } from './services/right-panel-attributes-builder.service';
import { UsersGridODataService } from './services/users/users.service';
import { SvgComponent } from './svg/svg.component';
import { UomConversionFormattedUnitPipe } from './uom/uom-conversion-formatted-unit.pipe';
import { UomConversionPipe } from './uom/uom-conversion.pipe';
import { ValidationMessagesComponent } from './validation/validation-messages/validation-messages.component';
import { ValidationService } from './validation/validation.service';
import { PageWrapperComponent } from './wrappers/page-wrapper/page-wrapper.component';
import { PopupWrapperComponent } from './wrappers/popup-wrapper/popup-wrapper.component';

// For Angular 11 + 12
// import { NgJsonEditorModule } from '@maaxgr/ang-jsoneditor';
// Starting Angular 13

const declarations: Array<Type<any> | any[]> = [
  SvgComponent,
  AutoCompleteComponent,
  ErrorWindowComponent,
  AlarmTriggerPointPipe,
  AlertWindowComponent,
  DateRangeFilterComponent,
  DayMonthFilterComponent,
  DayMonthHeader,
  UomConversionPipe,
  UomConversionFormattedUnitPipe,
  GridBindingDirective,
  ExportExcelButtonComponent,
  ExportPdfButtonComponent,
  ColumnStylePipe,
  ExtendDatePipe,
  ExtendDecimalPipe,
  HighlightSearchPipe,
  CapitalizePipe,
  CapitalizeSentencePipe,
  TranslateDefaultPipe,
  GridButtonsComponent,
  GridButtonsInternalComponent,
  CollapsibleButtonComponent,
  GridButtonsFirstSlotDirective,
  GridButtonsSecondSlotDirective,
  GridButtonsThirdSlotDirective,
  DragListComponent,
  ManageColumnComponent,
  IsSuperUserDirective,
  ExportAsImageButtonComponent,
  ExposeVariableDirective,
  NoResultsComponent,
  OnlyIntegerDirective,
  MaxNumberDigitsDirective,
  InputValidateDirective,
  SimpleTestDirective,
  PageWrapperComponent,
  GridHorizWheelScrollDirective,
  CollapsibleContainerComponent,
  ValidationMessagesComponent,
  HighlightSearchPipe,
  SafeHtmlPipe,
  UserNamePipe,
  TypeOfPipe,
  DragListVirtualComponent,
  SelectDragListVirtualComponent,
  DragListCustomComponent,
  DragListCardComponent,
  PlaceholderOverlayComponent,
  InterceptClickDirective,
  PopupWrapperComponent,
  ExportExcelComponent,
  ButtonsGroupComponent,
  HasPermissionDirective,
  DateTimeRangeComponent,
  DateTimeRangeErrorsComponent,
  TimeMaskDirective,
  DaysMaskDirective,
  EligibilityPopupComponent, // previously in alc module
  JsonEditorComponent,
  EditGridSettingsPopupComponent,
  WRouterLinkDirective,
  AutocompleteStandaloneComponent,
  FixedSizeElementDirective,
];

const exportableModules: Array<Type<any> | ModuleWithProviders<{}> | any[]> = [
  KendoSharedModule,
  MaterialSharedModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  CommonModule,
  IMaskModule,
  TranslateModule, // To have access to the pipe
  WlmSpinnerModule,
  LabelValueListModule,
  IconModule,
  ColorPickerModule,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgJsonEditorModule,
];

@NgModule({
  declarations: declarations,
  imports: [...exportableModules, OAuthModule.forRoot()],
  exports: [...declarations, ...exportableModules, OAuthModule] as (any[] | Type<any>)[],
  providers: [
    GridColumnHelperService,
    GridSettingsService,
    ColumnEnumsService,
    TitleMethodsService,
    CellStyleService,
    LogsInterfacesService,
    ChartHelperService,
    DialogService,
    ColorHelperService,
    StringHelperService,
    ArrayHelperService,
    ExtendDecimalPipe,
    GridHelperService,
    AlarmTriggerPointPipe,
    LocalStorageService,
    PersistencyService,
    PipeGridODataService,
    UomConversionPipe,
    EventService,
    IconLoaderService,
    GridHelperService,
    SharedConstantsService,
    ValidationService,
    NavMenuBuilderHelperService,
    WlmResizeObserverService,
    AlarmsService,
    { provide: 'AlarmsService', useExisting: AlarmsService },
    DateHelperService,
    { provide: 'DateHelperService', useExisting: DateHelperService },
    UsersGridODataService,
    { provide: 'UsersGridODataService', useExisting: UsersGridODataService },
    matDateFormatsProvider,
    { provide: NgxMatDateAdapter, useClass: CustomNgxDatePickerAdapter },
    UserNamePipe,
    RightPanelAttributesService,
    ButtonColumnService,
    //Dependencies:
    WorkOrderService,
    { provide: 'WorkOrderService', useExisting: WorkOrderService },
    LeaksService,
    { provide: 'LeaksService', useExisting: LeaksService },
    FiltrableItemManagerService,
    ExcelHelperService,
  ],
})
export class CommonSharedModule {
  static injector: Injector;

  constructor(injector: Injector) {
    CommonSharedModule.injector = injector;
  }

  static forFeature(config: CommonSharedModuleSettings): ModuleWithProviders<CommonSharedModule> {
    const filtrableItemServices = config.filtrableItemServices ?? [];

    return {
      ngModule: CommonSharedModule,
      providers: filtrableItemServices.map((service) => ({
        provide: BaseFiltrableItemService as any,
        multi: true,
        useClass: service,
      })),
    };
  }
}
