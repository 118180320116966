import { Injectable, Injector } from '@angular/core';
import { ISapLeakCodeDto } from '@common-modules/dependencies/alc/sap-leak-code.dto';
import { GenericCrudService } from '@common-modules/generic-crud/generic-crud.service';
import { BaseService } from '@common-modules/shared/base.service';
import { Observable, map } from 'rxjs';
import { IObjectValidationDto } from './leakage-type-group-validation.dto';

@Injectable()
export class SapLeakCodeCrudService
  extends BaseService
  implements GenericCrudService<ISapLeakCodeDto, string>
{
  private _entityUrl: string;

  constructor(injector: Injector) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/sap-leak-codes`;
  }

  getAll(): Observable<ISapLeakCodeDto[]> {
    return this.httpCacheClient.get<ISapLeakCodeDto[]>(this._entityUrl);
  }

  create(entity: ISapLeakCodeDto): Observable<ISapLeakCodeDto> {
    return this.httpCacheClient.post<ISapLeakCodeDto>(this._entityUrl, entity);
  }

  update(entity: ISapLeakCodeDto): Observable<ISapLeakCodeDto> {
    return this.httpCacheClient.put<ISapLeakCodeDto>(this._entityUrl, entity);
  }

  delete(id: string): Observable<boolean> {
    return this.httpCacheClient.delete(`${this._entityUrl}/${id}`).pipe(map(() => true));
  }

  getId(entity: ISapLeakCodeDto): string {
    return entity.sapLeakCode;
  }

  validate(entity: ISapLeakCodeDto, isCreate?: boolean): Observable<IObjectValidationDto> {
    return this.httpCacheClient.post<IObjectValidationDto>(
      `${this._entityUrl}/validate?isCreate=${isCreate ? 'true' : 'false'}`,
      entity
    );
  }
}
