import { Injectable } from '@angular/core';
import { IGisLayerYear } from '@common-modules/dependencies/map/gis-layer-year';
import { DynamicSettingsService } from '@common-modules/shared/config/dynamic-settings.service';
import { SettingsService } from '@common-modules/shared/config/settings.service';
import { ColorHelperService } from '@common-modules/shared/helpers/color-helper.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { IGisLayerDto } from '@common-modules/shared/model/gis/gis-layer.dto';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as mapboxgl from 'mapbox-gl';
import { AnyLayer, GeoJSONSource } from 'mapbox-gl';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapDefaults } from './map-defaults';
import { MapThematicKpi } from './map-thematic/models/map-thematic-kpi';
import { KpiSettingValue } from './map-thematic/models/map-thematic-kpi-value';
import { MapThematicKpiSettngs } from './map-thematic/models/map-thematic-settings';
import { MapThemes } from './map-themes-mapping';
import { MapLayerSetting } from './map-zoom-configuration-popup/models/map-layer-setting';
import { MapLayerZoom } from './map-zoom-configuration-popup/models/map-layer-zoom';
import { MapFeatureTypes } from './models/map-feature-types';

export interface BaseSource extends mapboxgl.Sources {
  [sourceName: string]: mapboxgl.AnySourceData;
}

export interface VectorSource extends mapboxgl.VectorSource, IGisLayerDto {
  layerName: string;
}

export interface VectorSources extends mapboxgl.Sources {
  [sourceName: string]: VectorSource;
}

const BASE_LAYER = 'base';
const MAX_ZOOM = 17;
const MAX_ZOOM_BASE = 20;
const MAX_ZOOM_ZONE = 15;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MapLayerSourcesService {
  private readonly _nonClusterZoomLimit = 18;
  private readonly _leakLayerPrefix = '777';
  private readonly _activityLayerPrefix = '888';

  private readonly _maxZoomCluster: number;

  maxSourceVectorZoom = MAX_ZOOM;
  maxSourceZoneZoom = MAX_ZOOM_ZONE;
  maxBaseSourceZoom = MAX_ZOOM_BASE;
  baseLayerName = BASE_LAYER;

  private _mapLayerSettings: MapLayerSetting[];
  public get mapLayerSettings(): MapLayerSetting[] {
    return this._mapLayerSettings;
  }

  private _hierarchyElementTypes: number[];
  private _networkElementTypes: number[];
  private _paintBaseLayer: any = {
    'raster-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      0,
      0.7,
      16,
      0.6,
      18,
      0.55,
      19,
      0.5,
      22,
      0.2,
    ],
  };

  constructor(
    private _globalsService: GlobalsService,
    private _settingsService: SettingsService,
    private _dynamicSettingsService: DynamicSettingsService,
    private _objectHelperService: ObjectHelperService,
    private _colorHelperService: ColorHelperService
  ) {
    this.setNEHETypeIds();

    this._maxZoomCluster =
      this._settingsService.settings.gis.map.maxZoomCluster ?? this._nonClusterZoomLimit;
  }

  getBaseSource(mapTheme: MapThemes, base?: string): Observable<BaseSource> {
    const { raster } = this._settingsService.gis;

    let baseTiles = raster.tiles;

    if (
      base?.length > 0 &&
      !base.startsWith('empty') &&
      mapTheme &&
      raster.tilesSet &&
      raster.tilesSet[base] !== undefined &&
      raster.tilesSet[base][mapTheme] !== undefined
    ) {
      baseTiles = raster.tilesSet[base][mapTheme];
    }

    return of({
      base: {
        type: 'raster',
        tiles: baseTiles,
        tileSize: raster.size,
        maxzoom: this.maxBaseSourceZoom,
      },
    });
  }

  getLayers(): Observable<VectorSources> {
    return this._globalsService.getGisLayers().pipe(
      map((layers) => {
        const vectorSources = layers.map((layer) => this.getSource(layer));

        return vectorSources.reduce((x, y, z, a) => Object.assign({}, ...a));
      })
    );
  }

  getFiltrableGisLeakYears(): Observable<IGisLayerYear[]> {
    return this._globalsService.getFiltrableGisLeakYears();
  }

  getTileSetTypes(): string[] {
    let tilesSetNames = [];

    if (!this._settingsService.gis.raster.tilesSet) {
      return tilesSetNames;
    }

    Object.entries(this._settingsService.gis.raster.tilesSet).forEach((entry) => {
      if (this.validTilesSet(entry)) {
        tilesSetNames.push(entry[0]);
      }
    });

    return tilesSetNames;
  }

  public getThematicKpiSettings(): Observable<MapThematicKpi[]> {
    return this._dynamicSettingsService.getUserMapKpiSettings().pipe(
      map((kpiSettings) => {
        let kpis: MapThematicKpi[] = [];

        if (!kpiSettings) {
          return kpis;
        }

        const parsedKpiSettings = this._objectHelperService.lowerCapitalizeKeysDeep(
          kpiSettings
        ) as MapThematicKpiSettngs;

        if (parsedKpiSettings?.categories) {
          Object.values(parsedKpiSettings.categories).forEach((value) => {
            const kpi = new MapThematicKpi(value.categoryKey, value.values);
            kpis.push(kpi);
          });
        }

        return kpis;
      })
    );
  }

  public getMapLayerSettings(): Observable<MapLayerSetting[]> {
    return this._dynamicSettingsService.getUserMapLayerSettings().pipe(
      map((settings) => {
        const parsedSettings = this._objectHelperService.lowerCapitalizeKeysDeep(
          settings
        ) as MapLayerSetting[];

        const mapLayerSettings = Object.values(parsedSettings).map(
          (setting) => new MapLayerSetting({ layerId: setting.layerId, value: setting.value })
        );

        this._mapLayerSettings = mapLayerSettings;

        return mapLayerSettings;
      })
    );
  }

  public isLeakLayer(layerId: number): boolean {
    if (layerId === undefined || layerId === null) {
      return false;
    }

    return layerId.toString().startsWith(this._leakLayerPrefix);
  }

  public isActivityLayer(layerId: number): boolean {
    if (layerId === undefined || layerId === null) {
      return false;
    }

    return layerId.toString().startsWith(this._activityLayerPrefix);
  }

  public isHELayer(layerId: number): boolean {
    if (layerId === undefined || layerId === null) {
      return false;
    }

    return this._hierarchyElementTypes.includes(+layerId);
  }

  public isNELayer(layerId: number): boolean {
    if (layerId === undefined || layerId === null) {
      return false;
    }

    return this._networkElementTypes.includes(+layerId);
  }

  public getBaseLayer(paintBaseLayerParam?: any): mapboxgl.Layer {
    return {
      id: this.baseLayerName,
      type: 'raster',
      source: this.baseLayerName,
      minzoom: 0,
      maxzoom: 22,
      paint: paintBaseLayerParam ? paintBaseLayerParam : this._paintBaseLayer,
    };
  }

  public getHeSelectedLayer(sourceName: string): mapboxgl.Layer {
    const layer = {
      id: sourceName,
      type: 'line',
      source: sourceName,
      layout: {},
      paint: {
        'line-color': '#aa00ff',
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0,
          0.01,
          6,
          1,
          10,
          2,
          13,
          4,
          16,
          7,
          19,
          10,
          22,
          12,
        ],
        'line-opacity': ['interpolate', ['linear'], ['zoom'], 0, 1, 16.5, 0.9, 20, 0.7, 22, 0.4],
      },
    };

    return layer;
  }

  public getZoneLayer(vectorSource: VectorSource): mapboxgl.Layer[] {
    const { gisLayerId, gisLayerSource, isZone, isLine } = vectorSource;

    let backgroundPaint: any = {
      'fill-outline-color': 'hsla(0, 0%, 0%, 0)',
      'fill-color': this._colorHelperService.hexAToRGBA(vectorSource.iconColor),
      'fill-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0.7,
        6,
        0.4,
        10,
        0.33,
        17,
        0.1,
        22,
        0.05,
      ],
    };
    if (gisLayerSource.endsWith('-merged')) {
      backgroundPaint = {
        'fill-pattern': 'merged-zones-pattern',
      };
    }

    const layerSource = `layer${gisLayerSource}`;

    const bgLayer: mapboxgl.Layer = {
      id: `${gisLayerSource}-background`,
      type: 'fill',
      source: layerSource,
      'source-layer': vectorSource.layerName,
      layout: {},
      paint: {
        ...backgroundPaint,
      },
    };

    const selectedLayer: mapboxgl.Layer = {
      id: `${gisLayerSource}-selected`,
      type: 'fill',
      source: layerSource,
      'source-layer': vectorSource.layerName,
      layout: {},
      paint: {
        'fill-outline-color': 'hsla(0, 0%, 0%, 0)',
        'fill-color': this._colorHelperService.hexAToRGBA(vectorSource.iconColor),
        'fill-opacity': 1,
      },
      filter: ['in', 'id', ''],
    };

    const borderLayer: mapboxgl.Layer = {
      id: `${gisLayerSource}-border`,
      type: 'line',
      source: layerSource,
      'source-layer': vectorSource.layerName,
      layout: {},
      paint: {
        'line-color': this._colorHelperService.hexAToRGBA(vectorSource.iconStrokeColor),
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0,
          0.01,
          8,
          0.1,
          10,
          0.5,
          13,
          1.5,
          16,
          4,
          17,
          5,
          22,
          12,
        ],
        'line-opacity': ['interpolate', ['linear'], ['zoom'], 0, 1, 16.5, 0.9, 20, 0.7, 22, 0.4],
      },
    };

    const zoom = this.getZoomsByLayer(gisLayerId, isZone, isLine, true);

    this.addZoomsToLayer(bgLayer, zoom?.min, zoom?.max);
    this.addZoomsToLayer(selectedLayer, zoom?.min, zoom?.max);
    this.addZoomsToLayer(borderLayer, zoom?.min, zoom?.max);

    return [bgLayer, selectedLayer, borderLayer];
  }

  public getLineLayer(vectorSource: VectorSource): mapboxgl.Layer {
    const { gisLayerId, layerName, isZone, isLine, iconStrokeColor } = vectorSource;

    const layer: mapboxgl.Layer = {
      id: layerName,
      type: 'line',
      source: layerName,
      'source-layer': layerName,
      layout: {},
      paint: {
        'line-color': this._colorHelperService.hexAToRGBA(iconStrokeColor),
        'line-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0,
          0,
          7.99,
          0,
          10,
          0.7,
          12,
          0.75,
          14,
          1,
          22,
          1,
        ],
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          0.7,
          10,
          1.5,
          12,
          2,
          13,
          2.25,
          16,
          4,
          18,
          6,
          19,
          9,
          22,
          30,
        ],
      },
    };

    const zoom = this.getZoomsByLayer(gisLayerId, isZone, isLine, true);

    this.addZoomsToLayer(layer, zoom?.min, zoom?.max);

    return layer;
  }

  public getSymbolLayer(vectorSource: VectorSource): mapboxgl.AnyLayer | mapboxgl.AnyLayer[] {
    const { gisLayerId, layerName, gisLayerSource, iconColor } = vectorSource;

    const hexColor = this._colorHelperService.hexAToRGBA(iconColor);
    const iconName = `layer-${gisLayerId.toString()}-icon`;
    const isNonClustered = gisLayerSource.endsWith('-nc');

    const symbolLayerOptions = {
      'source-layer': layerName,
      type: 'symbol',
      layout: {
        'icon-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0,
          0,
          6,
          0,
          7,
          0.5,
          10,
          0.7,
          12,
          0.8,
          15,
          1,
          18,
          1.5,
          22,
          2,
        ],
        'icon-allow-overlap': true,
        'icon-image': ['coalesce', ['image', iconName]],
      },
      paint: {
        'icon-color': hexColor,
      },
    };

    return this.getLayer(vectorSource, isNonClustered, symbolLayerOptions);
  }

  public getPointLayer(vectorSource: VectorSource): mapboxgl.AnyLayer | mapboxgl.AnyLayer[] {
    const sourceLayer = vectorSource.layerName;
    const circleStrokeColor = this._colorHelperService.hexAToRGBA(vectorSource.iconStrokeColor);
    const circleColor = this._colorHelperService.hexAToRGBA(vectorSource.iconColor);
    const isNonClustered = vectorSource.gisLayerSource.endsWith('-nc');

    const pointOptions = this.getBasePointLayerOptions(sourceLayer, circleStrokeColor, circleColor);

    return this.getLayer(vectorSource, isNonClustered, pointOptions);
  }

  public getThematicLayers(
    vectorSources: [string, VectorSource][],
    kpiValues: KpiSettingValue[],
    outOfRangeMapColor?: string
  ): mapboxgl.Layer[] {
    let layers = [];

    vectorSources.forEach((vectorSource) => {
      const { gisLayerId, layerName, gisLayerSource } = vectorSource[1];

      kpiValues.forEach((kpi) => {
        const steps: (string | number)[] = [];
        const fillColor = [
          'step',
          ['to-number', ['get', `${kpi.kpiType}-${kpi.kpiProperty}`], Number.MIN_SAFE_INTEGER],
          'hsla(0, 0%, 0%, 0)',
        ];

        const stepsByLayer = kpi.steps.find((x) => x.level == gisLayerId)?.steps;

        if (!stepsByLayer) {
          return;
        }

        Object.values(stepsByLayer).forEach((step) => {
          steps.push(step.value, step.color);
        });

        if (outOfRangeMapColor) {
          fillColor[2] = outOfRangeMapColor;
        }

        const layerId = `${gisLayerSource}-${kpi.kpiType}-${kpi.kpiProperty}`;
        const layerSource = `layer${gisLayerSource}`;

        let backgroundPaint: any = {
          'fill-color': [...fillColor, ...steps],
          'fill-opacity': 0.8,
        };

        if (gisLayerSource.includes('merge')) {
          backgroundPaint = {
            'fill-color': [...fillColor, ...steps],
            'fill-opacity': 0.8,
            'fill-pattern': 'merged-zones-pattern',
          };
        }
        const kpiLayers = [
          {
            id: layerId,
            type: 'fill',
            source: layerSource,
            'source-layer': layerName,
            layout: {
              visibility: 'none',
            },
            paint: {
              ...backgroundPaint,
            },
          },
          {
            id: `${layerId}-border`,
            type: 'line',
            source: layerSource,
            'source-layer': layerName,
            layout: {
              visibility: 'none',
            },
            paint: {
              'line-color': 'black',
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                0.01,
                8,
                0.1,
                10,
                0.5,
                13,
                1.5,
                16,
                4,
                17,
                5,
                22,
                12,
              ],
              'line-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                1,
                16.5,
                0.9,
                20,
                0.7,
                22,
                0.4,
              ],
            },
          },
        ];

        layers = layers.concat(kpiLayers);
      });
    });

    return layers as mapboxgl.Layer[];
  }

  private setNEHETypeIds() {
    const heTypes$ = this._globalsService.getHierarchyElementTypes();
    const neTypes$ = this._globalsService.getNetworkElementTypes();
    const neSubTypes$ = this._globalsService.getNetworkElementSubTypes();

    combineLatest([heTypes$, neTypes$, neSubTypes$])
      .pipe(untilDestroyed(this))
      .subscribe(([heTypes, neTypes, neSubTypes]) => {
        this._hierarchyElementTypes = heTypes
          .filter((f) => f.networkElementTypeId)
          .map((m) => m.networkElementTypeId);

        this._networkElementTypes = neTypes
          .filter((f) => f.networkElementTypeId)
          .map((m) => m.networkElementTypeId);

        this._networkElementTypes = this._networkElementTypes.concat(
          neSubTypes.map((m) => m.networkElementSubTypeId)
        );
      });
  }

  private getBasePointLayerOptions(
    sourceLayer: string,
    circleStrokeColor: any,
    circleColor: any
  ): any {
    return {
      type: 'circle',
      'source-layer': sourceLayer,
      layout: {},
      paint: {
        'circle-stroke-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0,
          3,
          4,
          3,
          6,
          2,
          11,
          2,
          18,
          5,
          19,
          15,
        ],
        'circle-stroke-color': circleStrokeColor,
        'circle-color': circleColor,
        'circle-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0,
          0,
          9.5,
          0.5,
          10,
          0.9,
          19,
          0.9,
          22,
          0.9,
        ],
        'circle-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0,
          3,
          4,
          4,
          6,
          4,
          11,
          5,
          18,
          7,
          19,
          15,
        ],
      },
    };
  }

  private getSource(layer: IGisLayerDto): VectorSources {
    const { gisLayerId, gisLayerSource } = layer;

    const layerName = `layer${gisLayerSource}`;
    const vector: VectorSources = {};
    const maxZoom = this.getMaxSourceZoom(layer);

    layer['tolerance'] = 1.25;
    layer['buffer'] = 0;

    vector[layerName] = {
      layerName: `layer${gisLayerId}`,
      maxzoom: maxZoom,
      type: 'vector',
      tiles: [this.getUrl(gisLayerSource)],
      ...layer,
    };

    vector[layerName]['tolerance'] = 1.25;
    vector[layerName]['buffer'] = 0;

    return vector;
  }

  public getSourceFeatures(layer: GeoJSON.FeatureCollection): GeoJSONSource {
    if (!layer) {
      return null;
    }

    const vector: GeoJSONSource = new GeoJSONSource();
    vector.type = 'geojson';
    vector.setData(layer);
    return vector;
  }

  public getFeaturesLayer(
    featureType: MapFeatureTypes,
    sourceName: string,
    paintBaseLayerParam?: any
  ): mapboxgl.Layer {
    const layer = {
      id: sourceName,
      type: featureType,
      source: sourceName,
      minzoom: 0,
      maxzoom: 22,
    };

    if (featureType === MapFeatureTypes.Line) {
      layer['paint'] = {
        ...mapDefaults.gisLayer.line.paint,
        'line-color': mapDefaults.gisLayer.color,
      };
    } else if (featureType === MapFeatureTypes.Polygon) {
      layer['paint'] = {
        ...mapDefaults.gisLayer.polygon.paint,
        'fill-color': mapDefaults.gisLayer.color,
      };
    } else if (featureType === MapFeatureTypes.Point) {
      layer['layout'] = {
        ...mapDefaults.gisLayer.symbol.layout,
        'icon-image': ['coalesce', ['image', mapDefaults.iconName]],
      };
      layer['paint'] = {
        'icon-color': mapDefaults.gisLayer.color,
      };
    }
    return layer;
  }

  private getZoomsByLayer(
    gisLayerId: number,
    isZone: boolean,
    isLine: boolean,
    isNonClustered: boolean
  ): MapLayerZoom {
    const zoom = this.mapLayerSettings.find((s) => s.layerId === gisLayerId)?.value?.zoom;
    if (!zoom) {
      return null;
    }

    // Polygons and lines are not clustered
    if (isZone || isLine) {
      return zoom;
    }

    const { min, max } = zoom;

    const minNCZoom = max >= this._maxZoomCluster ? this._maxZoomCluster : max;

    // Clustered layers switch at higher zoom (from clustering to non-clustering source)
    // So, we need to ranges:
    // Clustering: [min, max-1)
    // Non-Clustering: [max-1, max)
    //
    if (isNonClustered) {
      return new MapLayerZoom({ min: minNCZoom, max: max });
    }

    //This zoom level cannot be reached. This is intended in order to avoid load clustered layers
    if (min >= this._maxZoomCluster) {
      return new MapLayerZoom({ min: 24, max: 24 });
    }

    return new MapLayerZoom({ min: min, max: minNCZoom });
  }

  private getMaxSourceZoom(layer: IGisLayerDto): number {
    const { gisLayerSource, isZone } = layer;
    const isNonClustered = gisLayerSource.endsWith('-nc');

    if (isNonClustered) {
      return this._nonClusterZoomLimit;
    }

    return isZone ? this.maxSourceZoneZoom : this.maxSourceVectorZoom;
  }

  private getUrl(layerSource: string): string {
    return `${this._settingsService.gis.glUrl}/data/layer-${layerSource}/{z}/{x}/{y}.pbf`;
  }

  private getLayer(
    vectorSource: VectorSource,
    isNonClustered: boolean,
    layerOptions: any
  ): mapboxgl.AnyLayer | mapboxgl.AnyLayer[] {
    const { layerName, gisLayerSource, isZone, isLine } = vectorSource;

    // Refer to GisLayerDto class for explanation about originalGisLayerId.
    const gisLayerId = vectorSource.originalGisLayerId ?? vectorSource.gisLayerId;

    const zoom = this.getZoomsByLayer(gisLayerId, isZone, isLine, isNonClustered);
    const min = zoom?.min;
    const max = zoom?.max;

    if (isNonClustered) {
      const nonClusteredId = `layer${gisLayerSource}`;

      const layer = {
        id: nonClusteredId,
        source: nonClusteredId,
        ...layerOptions,
      };

      return this.addZoomsToLayer(layer, min, max);
    }

    const clusterLayers = this.getClusteredLayers(layerName, min, max);

    let layer = {
      id: layerName,
      source: layerName,
      filter: ['==', ['get', 'c'], '1'],
      ...layerOptions,
    } as AnyLayer;
    layer = this.addZoomsToLayer(layer, min, max);

    clusterLayers.push(layer);

    return clusterLayers;
  }

  private addZoomsToLayer(layer, min, max) {
    if (min) {
      layer.minzoom = min;
    }
    if (max) {
      layer.maxzoom = max;
    }
    return layer;
  }

  private getClusteredLayers(
    layerName: string,
    minZoom: number,
    maxZoom: number,
    sourceLayer: string = undefined,
    filters: any[] = []
  ): mapboxgl.AnyLayer[] {
    let clusterFilter = ['>', ['to-number', ['get', `c`], -1], 1];

    if (filters?.length) {
      clusterFilter = [...filters, clusterFilter];
    }
    let clusterLayer = {
      id: `${layerName}-cluster`,
      type: 'circle',
      source: layerName,
      'source-layer': sourceLayer ? sourceLayer : layerName,
      filter: clusterFilter,
      paint: {
        'circle-color': [
          'step',
          ['to-number', ['get', `c`]],
          '#fd7f6f',
          50,
          '#7eb0d5',
          200,
          '#b2e061',
          400,
          '#bd7ebe',
          800,
          '#ffb55a',
          1500,
          '#ffee65',
          5000,
          '#beb9db',
          15000,
          '#fdcce5',
          50000,
          '#8bd3c7',
        ],
        'circle-radius': [
          'step',
          ['to-number', ['get', `c`]],
          10,
          50,
          15,
          200,
          20,
          400,
          25,
          800,
          30,
          1500,
          35,
          5000,
          40,
          15000,
          45,
          50000,
          50,
        ],
      },
    };

    let clusterCountLayer = {
      id: `${layerName}-cluster-count`,
      type: 'symbol',
      source: layerName,
      'source-layer': sourceLayer ? sourceLayer : layerName,
      filter: clusterFilter,
      layout: {
        'text-field': '{c}',
        'text-font': ['Roboto Medium', 'Open Sans Regular'],
        'text-size': 12,
        'text-allow-overlap': true,
      },
    };

    clusterLayer = this.addZoomsToLayer(clusterLayer, minZoom, maxZoom);
    clusterCountLayer = this.addZoomsToLayer(clusterCountLayer, minZoom, maxZoom);

    return [clusterLayer, clusterCountLayer] as any[];
  }

  private validTilesSet(tilesSet: [string, { [themeName: string]: string[] }]): boolean {
    const tileSetType = tilesSet[0];
    const tilesPerTheme = tilesSet[1];

    if (!this.validStringValue(tileSetType)) {
      return false;
    }

    for (const themeAndSet of Object.entries(tilesPerTheme)) {
      const tilesSet = themeAndSet[1];

      if (tilesSet.length === 0 || tilesSet.some((tiles) => !this.validStringValue(tiles))) {
        return false;
      }
    }

    return true;
  }

  private validStringValue(value: string) {
    return value?.length > 0 && !value.startsWith('#');
  }
}
